.profile-sidebar {
  .my-account-title {
    color: #212121;
    font-family: Poppins;
    font-size: 32px;
    font-weight: 600;
  }
  .shows {
    @media screen and (min-width: 600px) {
      display: none;
    }
  }
}
.my-account-title {
    color: #212121;
    font-family: Poppins;
    font-size: 32px;
    font-weight: 600;
  }
.drawer-width {
  .MuiDrawer-paper {
    width: 100% !important;
  }
}
.profile-sidebar {
  min-width: 20%;
  margin: 0 20px 0 0;
  @media screen and (max-width: "1200px") {
    min-width: 25%;
  }
  @media screen and (max-width: "940px") {
    min-width: 30%;
  }
  @media screen and (max-width: "710px") {
    min-width: 35%;
  }
  @media screen and (max-width: "600px") {
    min-width: 0%;
  }
}
