.profile-container {
  box-sizing: border-box;
  color: #212121;
  font-family: Poppins;
  font-size: 32px;
  min-width: 75%;
  @media screen and (max-width: "1200px") {
    min-width: 70%;
  }
  @media screen and (max-width: "940px") {
    min-width: 65%;
  }
  @media screen and (max-width: "710px") {
    min-width: 60%;
  }
  @media screen and (max-width: "600px") {
    min-width: 55%;
  }
  margin: 50px 20px;
}

.buyer-container {
  @media screen and (min-width: 600px) {
    display: flex;
  }
}

.top-heading {
  font-size: 24px !important;
  font-weight: 600 !important;
}

.add-addr-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 30px 0;
}

.plus-btn {
  width: 20px !important;
  height: 20px !important;
  margin-right: 10px;
  color: orange !important;
}

.add-text {
  font-size: 16px !important;
  color: #ff8502 !important;
  font-weight: 600 !important;
}

.addr-heading {
  font-size: 20px !important;
  font-weight: 600 !important;
  margin-bottom: 20px !important;
}

.other-addr {
  margin-top: 40px !important;
}

.addr-block {
  border: 1.5px solid #e4e4e4;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 20px;
}

.name-phone {
  position: relative;
}

.person-name {
  font-size: 18px !important;
  font-weight: 500 !important;
}

.address {
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #818181 !important;
  width: 80%;
}

.pincode {
  font-size: 14px !important;
  font-weight: 600 !important;
  margin-top: 10px !important;
}

.landmark {
  font-size: 16px !important;
  font-weight: 500 !important;
}

.info-modal {
  display: none;
  position: absolute;
  top: 5px;
  right: 20px;
  background-color: #fff;
  z-index: 15;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  box-shadow: -4px 4px 15px rgba(0, 0, 0, 0.15);
}

.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: -1;
}
