.download-invoice-modal-btn {
    background-color: none !important;
    border: 2px solid #4da450 !important;
    border-radius: 8px !important;
    color: #4da450 !important;
    font-family: Poppins !important;
    font-weight: 600 !important;   
}

.download-invoice {
    background-color: #fff !important;
    border: 2px solid #4da450 !important;
    border-radius: 8px !important;
    color: #4da450 !important;
    font-family: Poppins !important;
    font-weight: 600 !important;
    width: 200px !important;
    display: flex !important;
    justify-content: center !important;
    margin: 0 auto !important;
}

#pdf{
    margin-top: 20px;
    display: flex;
    justify-content: center;
    font-family: Poppins !important;
}

.invoice-main-div {
   width:50%;
   height:90vh;
   background-color:#fff;
   overflow:auto;
   border-radius: 5px;
   @media screen and (max-width: '1000px') {
    width: 70%;
   }
   @media screen and (max-width: '500px') {
    width: 80%;
   }
}

table {
    font-family: Arial, Helvetica, sans-serif !important;
    border-collapse: separate;
    width: 100%;
}

td {
    border: 1px solid #999 !important;
    text-align: right;
    padding: 5px 10px !important;
}

.desc-td {
    border: 1px solid #999 !important;
    text-align: left;
    padding: 5px 10px !important;
}

th {
    border: 1px solid #999 !important;
    text-align: center;
    padding: 5px 10px!important;
    font-weight: 600 !important;
}

.total {
    font-weight: 600;
}

tr:first-child {
    background-color: #dddddd;
}

.invoice-header, .invoice-title {
    display: flex;
    justify-content: center;
}

.invoice-title-text {
    font-size: 32px;
    font-weight: 600;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    margin-bottom: 30px;
}
.invoice-logo {
    display: grid;
    place-items: center;
    width: 300px;
}

.top-invoice-div {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row-reverse;
    margin: 0 10px 50px 10px;
}

.biller-div {
    width: 300px;
}

.text-title {
    font-weight: 600;
}

.cust-name {
    font-weight: 500;
}

.cust-addr, .cust-phone,
.cust-email {
    font-size: 14px;
}

.invoice-footer {
    display: flex;
    justify-content: center;
}

.invoice-footer-text {
    margin-top: 50px;
    font-size: 24px;
    font-weight: 700;
}