.form-container {
  margin: 30px 20px;
}

.top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-new-heading {
  font-size: 20px !important;
  font-weight: 600 !important;
}

.add-addr-field {
  width: 100%;
  color: yellow !important;
  // padding: 10px !important    ;
}

#name,
#mobile-number,
#pincode,
#locality,
#address,
#landmark,
#city,
#email,
#old_password,
#new_password,
#confirm_new_password {
  min-height: 48px !important;
}

.field-div {
  padding-top: 30px;
}

.addr-form-label {
  font-size: 14px !important;
  font-weight: 600 !important;
  margin-bottom: 5px !important;
}

.err-text {
  color: #f00;
  margin: 5px 10px 0 10px;
  font-family: Poppins;
  font-size: 14px;
}

.city-select {
  width: 100%;
}

.cityselect__indicator-separator {
  display: none;
}

.cityselect__control {
  border-radius: 8px !important;
  border: 1px solid #e4e4e4 !important;
  font-size: 16px !important;
  padding: 5px !important;
  font-family: Poppins !important;
}

.cityselect__placeholder {
  color: #818181 !important;
}

.cityselect__menu-list {
  font-size: 14px !important;
  font-family: Poppins !important;
  max-height: 120px !important;
  overflow-y: scroll !important;
}
.save-cancel-combo {
  display: flex;
  justify-content: space-around !important;
  // padding: 20px 0;
  margin: 20px 0px;
  @media screen and (max-width: 600px) {
    padding: 0px;
    flex-direction: column;
  }
  .save-cancel-btn1 {
    box-sizing: border-box !important;
    color: #000 !important;
    width: 13rem;
    font-family: "Poppins" !important;
    border-radius: 8px !important;
    height: 50px !important;
    padding: 10px 25px !important;
    border: 0.3px solid #939393 !important;
    @media screen and (max-width: 600px) {
      width: 90% !important;
      margin-left: 15px !important;
      margin-bottom: 15px !important;
    }
  }
  .save-cancel-btn2 {
    border-radius: 8px !important;
    width: 13rem !important;
    height: 50px !important;
    background: linear-gradient(90deg, #2d752f 0%, #9ec955 100%) !important;
    color: #fff !important;
    @media screen and (max-width: 600px) {
      width: 90% !important;
      padding: 10px 25px !important;
      margin-left: 15px !important;
      margin-bottom: 15px !important;
    }
  }
}
