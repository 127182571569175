.buyer-orders-list-container {
  max-height: 100vh;
  overflow: auto;
}

.buyer-orders-list-container::-webkit-scrollbar {
  display: none;
}

.orders-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
}
.search-filter-part {
  display: flex;
  align-items: center;
}
.search-filter {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.filter-orders-con {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    /* margin-right: 50px; */
    margin-left: 20px;
    width: 107px;
    height: 40px;
    border-radius: 8px;
    border: 1px solid #ebebeb;
}

.filter-text {
  font-size: 16px !important;
  font-family: Poppins !important;
  font-weight: 500 !important;
  color: #212121 !important;
}
.order-heading {
  font-size: 24px !important;
  font-weight: 600 !important;
  flex-grow: 2;
}

.orders-container {
  margin: 20px;
}

.order-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e8e8e8;
  padding: 10px;
  border-radius: 12px;
  margin: 30px 0;
}

.order-details {
  display: flex;
  align-items: center;
}

.order-img {
  margin-right: 10px;
  min-width: 80px !important;
  .fix-img {
    max-width: 70px !important;
    height: 70px !important;
    border-radius: 12px;
    background-color: #eee;
  }
}

.ord-prod-name {
  font-size: 16px !important;
  font-family: Poppins !important;
  font-weight: 500 !important;
  color: #212121 !important;
  margin-bottom: 5px !important;
}

.ord-prod-quantity {
  font-size: 14px !important;
  font-family: Poppins !important;
  font-weight: 300 !important;
  color: #999 !important;
}

.ord-prod-price {
  font-size: 14px !important;
  font-family: Poppins !important;
  font-weight: 500 !important;
  color: #212121 !important;
}

.status-text {
  color: #ff8502;
  border: 1px solid #ff8502;
  font-family: Poppins !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  border-radius: 24px !important;
  padding: 10px 20px;
}

.noorder-heading {
  font-family: Poppins !important;
  font-size: 24px !important;
}

.filter-accordion {
  position: absolute;
}

.filter-modal {
  position: absolute;
  top: 30px;
  min-width: 130px;
  left: 35px;
  right: 0px;
  top: 42px;
  background-color: #fff;
  z-index: 2;
}

.overlay1 {
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: -1;
}

.buyer-order-search {
    min-width: 33%;
    border: 1px solid #ebebeb !important;
    border-radius: 12px !important;
}

.filter-opt {
    margin: 0 5px 5px 5px !important;
    padding: 0 5px 5px 5px;
    font-family: Poppins !important;
    font-size: 14px !important;
    cursor: pointer;
}