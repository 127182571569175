body{
    // min-height: 100%;
}
.home {
    font-size: 14px !important;
    font-family: Poppins !important;
    font-weight: 400 !important;
    cursor: pointer;
    display: inline-block;
    margin-right: 5px !important;
}

.home-path {
    margin: 50px 0 0 40px;
}

.title {
    margin: 30px 0 20px 40px;
}

.product-section {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    margin: 0 30px;
    @media screen and (max-width: 690px){
        justify-content: center;
    }
}

.product-card {
    margin: 20px;
    min-width: 200px;
    cursor: pointer;
}

.prod-img{
    width: 275px;
    height: 275px;
}