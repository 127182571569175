.box-modal{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    background: rgb(255, 255, 255);
    border-radius: 10px;
    font-family: Poppins;
    font-style: normal;
    width: 540px;
    min-height: 335px;
    border-radius: 12px;
    background: #ffffff;
    box-shadow: 0px 3px 8px rgba(0,0,0,0.24);
    padding: 50px;
    @media screen and (min-width: 400px) and (max-width: 600px) {
        width: 360px;
      }
      @media screen and (max-width: 400px) {
        width: 90%;
        padding: 20px 20px 20px 20px !important;
      }
}
.div-modal{
    text-align: center !important;
}
.div-modal-h4{
    text-align: center !important;
    height: 36px;
    color: #212121;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    text-align: center;
}
.p-tag-modal{
    height: 50px;
    color: #424242;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    text-align: center;
}
.modal-btn{
    width: 412px !important;
    height: 56px !important;
    background: linear-gradient(90deg, #2D752F, #9EC955);
    color: #ffffff !important;
    font-family: Poppins !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 16px !important;
    text-align: center !important;
    text-transform: uppercase !important;
    border-radius: 12px !important;
    cursor: pointer !important;
    
}