.containerDas {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  font-family: "Poppins", sans-serif;
  .bannerDas {
    background: #ffffff;
    box-shadow: 0px 4px 12px rgb(0 0 0 / 7%);
    position: relative;
    // @media screen and (max-width: 540px) {
    //   width: 100%;
    // }
    // @media screen and (max-width: 1000px) {
    // 	width: 100% !important;
    // }
    // @media only screen and (min-width: 840px) and (max-width: 1400px) {
    // 	width: 30% !important;
    // }
    .logo {
      @media screen and (max-width: 768px) {
        left: 30px;
      }

      top: 50px;
      left: 50px;
      position: absolute;
      object-fit: fill;
      z-index: 99;
      width: clamp(100px, 70%, 240px);
    }
    .banner-img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      position: relative;
      @media screen and (max-width: 540px) {
        height: 150px;
      }
    }
    .rights-reserved {
      opacity: 0.85;
      position: absolute;
      left: 50px;
      bottom: 30px;
      color: #ffffff;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      text-align: left;
      @media screen and (max-width: 991px) {
        left: 30px;
      }
      @media screen and (max-width: 540px) {
        bottom: 10px;
      }
    }
  }
  .auth-form-wrapper {
    background: #f8fafb;
    width: 100%;
    @media screen and (max-width: 991px) {
    }
    @media screen and (max-width: 540px) {
      width: 100%;
    }
    @media screen and (max-width: 840px) {
      width: 100% !important;
    }
  }
}
