.contact-submit-btn {
    background: linear-gradient(90deg, #2D752F, #9EC955) !important;
    color: #fff !important;
    font-family: Poppins !important;
    width: 100%;
    height: 48px !important;
    border-radius: 12px !important;
    font-size: 18px !important;
}

input[placeholder="Contact Email"] {
    height: 48px !important; 
}

.password-div1 {
    width: 560px;
    padding: 25px;
    margin: 20px auto;
    border-radius: 12px;
    background: #ffffff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.07);
    @media screen and (max-width: 600px) {
        width: 100%;
      }
      @media screen and (min-width: 610px) and (max-width: 900px) {
        width: calc(100% - 250px);
      }
}