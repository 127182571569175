.containerDas {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  font-family: "Poppins", sans-serif;
  .bannerDas {
    background: #ffffff;
    box-shadow: 0px 4px 12px rgb(0 0 0 / 7%);
    position: relative;

    // @media screen and (max-width: 540px) {
    //   width: 100%;
    // }
    .logo {
      @media screen and (max-width: 768px) {
        left: 30px;
      }

      top: 50px;
      left: 50px;
      position: absolute;
      object-fit: fill;
      z-index: 99;
      width: clamp(100px, 70%, 240px);
    }
    .banner-img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      position: relative;
      @media screen and (max-width: 540px) {
        height: 150px;
      }
    }
    .rights-reserved {
      opacity: 0.85;
      position: absolute;
      left: 50px;
      bottom: 30px;
      color: #ffffff;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      text-align: left;
      @media screen and (max-width: 991px) {
        left: 30px;
      }
      @media screen and (max-width: 540px) {
        bottom: 10px;
      }
    }
  }
  .auth-form-wrapper {
    background: #f8fafb;
    min-height: 100vh;
    @media screen and (max-width: 540px) {
      width: 100%;
    }
  }
  .profile-tabs-content {
    // min-height: 90vh;
  }

  .auth-form-wrapper-profile {
    @media screen and (max-width: 540px) {
      width: 100%;
    }
    .auth-form {
      position: relative;
      @media screen and (max-width: 600px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      //  margin: 10px;
    }
    .signup-link {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 100%;
      text-align: right;
      @media screen and (max-width: 540px) {
        text-align: center;
        right: auto;
      }
      a {
        color: orange;
        text-decoration: none;
        font-size: 16px;
        font-weight: 600;
      }
    }
    .login-form-profile {
      @media screen and (max-width: 768px) {
        width: 80%;
      }
      width: 500px;
      max-width: 540px;
      /* margin: 0 auto; */
      /* height: 56px; */
      padding-top: 30px;
      // margin-left: 24px;

      .MuiFormControl-root {
        padding: 5px 0;
        width: 100%;
      }
      .MuiOutlinedInput-root {
        width: 100% !important;
        border-radius: 12px !important;
      }
      .country-code {
        .react-select__control {
          display: flex;
          justify-content: center;
          align-content: center;
          border-radius: 15px;
          height: 48px;
          width: 100%;
        }
      }
      input {
        border-radius: 15px;
        height: 48px;
        width: 100%;
      }
      .input-lable {
        color: #212121;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
      }
      .welcome {
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 54px;
        /* identical to box height */
        color: #212121;
        @media screen and (max-width: 768px) {
          font-size: 32px;
        }
      }
    }
    .login-button {
      width: 100%;
      background-color: #2d752f;
      color: white;
      background: linear-gradient(90deg, #2d752f 0%, #9ec955 100%);
      background-blend-mode: normal;
      height: 56px;
      display: flex;
      justify-content: space-evenly;
      border-radius: 12px;
      cursor: pointer;
      .loader {
        width: 10px;
        height: 10px;
        color: white;
        font-size: 10px;
      }
      span {
        color: #ffffff;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
        text-align: center;
        text-transform: uppercase;
      }
    }
    .divider-section {
      display: flex;
      justify-content: space-between;
      align-content: center;
      align-items: center;
      p {
        color: grey;
        font-size: 15px;
      }
      .divider {
        width: 43%;
        color: black;
      }
    }
  }
}
.bank-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bank-header .MuiList-root {
  margin: 0px !important;
}
.bank-header .MuiList-root .MuiListItem-gutters {
  padding-left: 30px;
  @media screen and (max-width: 600px) {
    padding-left: 10px;
  }
}
.title-lines {
  span {
    //   width: 133px;
    height: 30px;
    color: #212121;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    @media screen and (max-width: 600px) {
      font-size: 14px;
    }
  }
}
.div-closee {
  margin-left: auto;
  margin-top: 10px;
  margin-right: 35px;
  cursor: pointer;
}
.table-box {
  //   width: calc(100% - 250px);
  margin-left: 250px;
  @media screen and (max-width: 600px) {
    margin-left: 10px;
    margin-right: 10px;
  }
}
.profile-div {
  width: 560px;
  padding: 25px;
  margin-left: 250px;
  //height: 814px;
  border-radius: 12px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.07);
  @media screen and (max-width: 600px) {
    width: 100%;
    margin-left: 0px;
  }
  @media screen and (min-width: 610px) and (max-width: 900px) {
    width: calc(100% - 250px);
    margin-left: 250px;
  }
}
.div-profile-img {
  width: 138px;
  height: 138px;
  background: white;
  position: relative;
  border-radius: 50%;
  @media screen and (max-width: 600px) {
    margin: 0 0 0px 80px;
  }
}
.img-profile {
  height: 138px;
  border-radius: 72px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgb(0 0 0 / 15%);
  // @media screen and (max-width: 600px) {
  //   margin-left: 80px;
  // }
}
.update-icon-container {
  background: white;
  width: 30px;
  height: 30px;
  border-radius: 72px;
  position: absolute;
  bottom: 5px;
  right: 5px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  .update-icon {
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
}

.password-div {
  width: 560px;
  padding: 25px;
  margin-left: 250px;
  border-radius: 12px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.07);
  @media screen and (max-width: 600px) {
    width: 100%;
    margin-left: 0px;
  }
  @media screen and (min-width: 610px) and (max-width: 900px) {
    width: calc(100% - 250px);
    margin-left: 250px;
  }
}
.bank-details-data {
  // width: 500px;
  height: 184px;
  border-radius: 6px;
  border: solid 1px #eaeaea;
  background: #ffffff;
  padding: 15px;
  @media screen and (max-width: 360px) {
    height: 200px;
  }
}
.data-bank {
  //width: 150px;
  height: 24px;
  color: #212121;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 0px 0 0px 0 !important;
  display: flex;
}
.content-bank {
  width: 68px;
  /* height: 18px; */
  border-radius: 20px;
  border: solid 1px #ff8502;
  background: rgba(255, 133, 2, 0.06);
  /* width: 47px; */
  /* height: 18px; */
  color: #ff8502;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  /* line-height: 18px; */
  text-align: center;
  margin-left: 10px;
}
.menuImg {
  width: 4.5px;
  height: 18px;
  background: #212121;
  margin-left: auto;
  cursor: pointer;
}
.bank-primary-menu {
  .MuiMenu-paper {
    max-height: calc(100% - 96px);
    opacity: 1;
    transform: none;
    transition: opacity 237ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      transform 158ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    top: 220px !important;
    left: 664px !important;
    transform-origin: -16px 18px;
    width: 150px;
    /* height: 82px; */
    border-radius: 12px;
    background: #ffffff;
    box-shadow: 0px 2px 7px rgb(195 195 195 / 50%);
  }
}
.account-detail {
  opacity: 0.7;
  height: 21px;
  color: #212121;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  @media screen and (max-width: 600px) {
    height: 30px;
  }
}
.account-number-details {
  display: flex;
  justify-content: space-between;
}
.bank-account-detail-h4 {
  height: 24px;
  color: #212121;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.add-new-bank {
  height: 24px;
  color: #ff9938;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
}
.bank-form-profile {
  padding-left: 30px !important;
  padding-right: 30px !important;
  @media screen and (max-width: 600px) {
    width: 100%;
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
  width: 500px;
  max-width: 540px;
  /* margin: 0 auto; */
  /* height: 56px; */
  padding-top: 20px;
  padding-bottom: 20px;
  // margin-left: 24px;

  .MuiFormControl-root {
    padding: 5px 0;
    width: 100%;
  }
  .MuiOutlinedInput-root {
    width: 100% !important;
    border-radius: 12px !important;
  }
  .country-code {
    .react-select__control {
      display: flex;
      justify-content: center;
      align-content: center;
      border-radius: 15px;
      height: 48px;
      width: 100%;
    }
  }
  input {
    border-radius: 15px;
    height: 48px;
    width: 100%;
  }
  .input-lable {
    color: #212121;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
  }
  .welcome {
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
    /* identical to box height */
    color: #212121;
    @media screen and (max-width: 768px) {
      font-size: 32px;
    }
  }
  .login-button {
    width: 50% !important;
    background-color: #2d752f;
    color: white;
    background: linear-gradient(90deg, #2d752f 0%, #9ec955 100%);
    background-blend-mode: normal;
    height: 56px;
    display: flex;
    justify-content: space-evenly;
    border-radius: 12px;
    .loader {
      width: 10px;
      height: 10px;
      color: white;
      font-size: 10px;
    }
    span {
      color: #ffffff;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
      text-align: center;
      text-transform: uppercase;
    }
  }
  .button-divider {
    display: flex;
  }
  .prev-button {
    color: #000000;
    width: 50% !important;
    height: 56px;
    background: #fff !important;
    background-blend-mode: normal;
    height: 56px;
    display: flex;
    justify-content: space-evenly;
    border-radius: 12px !important;

    span {
      color: #000000;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
      text-align: center;
      text-transform: uppercase;
    }
  }
}
.footer-line {
  display: flex;
  padding: 20px;
  margin-top: 535px !important;
  display: flex;
  opacity: 0.85;
  position: absolute;
  /* left: 50px; */
  top: 433px;
  /* bottom: -300px; */
  color: #ffffff;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  padding: 24px;
}
.terms-privacy {
  margin-left: 682px;
  opacity: 0.6;
  //width: 301px;
  height: 21px;
  color: #212121;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  display: flex;
  cursor: pointer;
}
.grow-cart-auth {
  opacity: 0.6;
  // width: 301px;
  height: 21px;
  color: #212121;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}
.refferal-div {
  // width: 1120px;
  margin-left: 250px;
  height: 814px;
  border-radius: 12px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.07);
  text-align: center;
  .login-button {
    width: 50% !important;
    margin: auto;
    background-color: #2d752f;
    color: white;
    background: linear-gradient(90deg, #2d752f 0%, #9ec955 100%);
    background-blend-mode: normal;
    height: 56px;
    display: flex;
    justify-content: space-evenly;
    border-radius: 12px;
    .loader {
      width: 10px;
      height: 10px;
      color: white;
      font-size: 10px;
    }
    span {
      color: #ffffff;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
      text-align: center;
      text-transform: uppercase;
    }
  }
  @media screen and (max-width: 600px) {
    margin-left: 0px;
  }
}
.refferal-image {
  width: 400px;
  height: 400px;
  @media screen and (max-width: 600px) {
    width: 200px;
    height: 200px;
  }
}

.ref-h4 {
  height: 60px;
  color: #212121;
  font-family: Poppins;
  font-size: 21px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
}
.h4-ref-div {
  text-align: center;
  width: 404px !important;
  margin: auto;
  @media screen and (max-width: 600px) {
    width: 300px !important;
  }
}
.ref-code-div {
  margin: auto;
  width: 166px;
  height: 51px;
  border-radius: 12px;
  background: #fcefe0;
}
.h4-ref-code {
  height: 24px;
  color: #ff8502;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding-top: 12px;
}
.img-ref {
  margin-left: 15px;
  cursor: pointer;
}
.ref-h4-2 {
  height: 24px;
  color: #777777;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding-bottom: 32px;
  padding-top: 5px;
}
.multiline-text {
  .MuiOutlinedInput-multiline {
    padding: 9.5px 1px;
    overflow-wrap: anywhere;
  }
}
.profile-require {
  margin: 0;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  color: #f44336;
  width: max-content;
}
.menuButton {
  margin-left: auto !important;
}
[data-reach-menu-list],
[data-reach-menu-items] {
  display: block !important;
  white-space: nowrap !important;
  border: none !important;
  //background: hsla(0, 100%, 100%, 0.99);
  outline: none !important;
  /* padding: 1rem 0; */
  font-size: 0% !important;
  width: 150px !important;
  // height: 82px !important;
  border-radius: 12px !important;
  background: #ffffff !important;
  padding: 1rem 0 !important;
  box-shadow: 0px 2px 7px rgba(195, 195, 195, 0.5) !important;

  // .main-div {
  //     overflow-y: inherit !important;
  //     position: fixed;
  // }
  .MuiButton-root.Mui-disabled {
    background: #bbb !important;
  }
  .password-div {
    width: 560px;
    padding: 25px;

    border-radius: 12px;
    background: #ffffff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.07);
  }
  .bank-details-data {
    // width: 500px;
    height: 184px;
    border-radius: 6px;
    border: solid 1px #eaeaea;
    background: #ffffff;
    padding: 15px;
  }
  .data-bank {
    //width: 150px;
    height: 24px;
    color: #212121;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin: 0px 0 0px 0 !important;
    display: flex;
  }
  .content-bank {
    width: 68px;
    /* height: 18px; */
    border-radius: 20px;
    border: solid 1px #ff8502;
    background: rgba(255, 133, 2, 0.06);
    /* width: 47px; */
    /* height: 18px; */
    color: #ff8502;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    /* line-height: 18px; */
    text-align: center;
    margin-left: 10px;
  }
  .menuImg {
    width: 4.5px;
    height: 18px;
    background: #212121;
    margin-left: auto;
    cursor: pointer;
  }
  .bank-primary-menu {
    .MuiMenu-paper {
      max-height: calc(100% - 96px);
      opacity: 1;
      transform: none;
      transition: opacity 237ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        transform 158ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      top: 220px !important;
      left: 664px !important;
      transform-origin: -16px 18px;
      width: 150px;
      /* height: 82px; */
      border-radius: 12px;
      background: #ffffff;
      box-shadow: 0px 2px 7px rgb(195 195 195 / 50%);
    }
  }
  .account-detail {
    opacity: 0.7;
    height: 21px;
    color: #212121;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }
  .account-number-details {
    display: flex;
  }
  .bank-account-detail-h4 {
    height: 24px;
    color: #212121;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
  .add-new-bank {
    height: 24px;
    color: #ff9938;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    cursor: pointer;
  }
  .bank-form-profile {
    padding-left: 30px !important;
    padding-right: 30px !important;
    @media screen and (max-width: 768px) {
      width: 80%;
    }
    width: 500px;
    max-width: 540px;
    /* margin: 0 auto; */
    /* height: 56px; */
    padding-top: 20px;
    padding-bottom: 20px;
    // margin-left: 24px;

    .MuiFormControl-root {
      padding: 5px 0;
      width: 100%;
    }
    .MuiOutlinedInput-root {
      width: 100% !important;
      border-radius: 12px !important;
    }
    .country-code {
      .react-select__control {
        display: flex;
        justify-content: center;
        align-content: center;
        border-radius: 15px;
        height: 48px;
        width: 100%;
      }
    }
    input {
      border-radius: 15px;
      height: 48px;
      width: 100%;
    }
    .input-lable {
      color: #212121;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
    }
    .welcome {
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 54px;
      /* identical to box height */
      color: #212121;
      @media screen and (max-width: 768px) {
        font-size: 32px;
      }
    }
    .login-button {
      width: 50% !important;
      background-color: #2d752f;
      color: white;
      background: linear-gradient(90deg, #2d752f 0%, #9ec955 100%);
      background-blend-mode: normal;
      height: 56px;
      display: flex;
      justify-content: space-evenly;
      border-radius: 12px;
      .loader {
        width: 10px;
        height: 10px;
        color: white;
        font-size: 10px;
      }
      span {
        color: #ffffff;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
        text-align: center;
        text-transform: uppercase;
      }
    }
    .button-divider {
      display: flex;
    }
    .prev-button {
      color: #000000;
      width: 50% !important;
      height: 56px;
      background: #fff !important;
      background-blend-mode: normal;
      height: 56px;
      display: flex;
      justify-content: space-evenly;
      border-radius: 12px !important;

      span {
        color: #000000;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
        text-align: center;
        text-transform: uppercase;
      }
    }
  }
  .footer-line {
    display: flex;
    padding: 20px;
  }

  .terms-privacy {
    margin-left: auto;
    opacity: 0.6;
    //width: 301px;
    height: 21px;
    color: #212121;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }
  .grow-cart-auth {
    opacity: 0.6;
    // width: 301px;
    height: 21px;
    color: #212121;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }
  .refferal-div {
    // width: 1120px;
    height: 814px;
    border-radius: 12px;
    background: #ffffff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.07);
    text-align: center;
    .login-button {
      width: 50% !important;
      margin: auto;
      background-color: #2d752f;
      color: white;
      background: linear-gradient(90deg, #2d752f 0%, #9ec955 100%);
      background-blend-mode: normal;
      height: 56px;
      display: flex;
      justify-content: space-evenly;
      border-radius: 12px;
      .loader {
        width: 10px;
        height: 10px;
        color: white;
        font-size: 10px;
      }
      span {
        color: #ffffff;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
        text-align: center;
        text-transform: uppercase;
      }
    }
  }
  .refferal-image {
    width: 400px;
    height: 400px;
    @media screen and (max-width: 600px) {
      width: 200px;
      height: 200px;
    }
  }
  .ref-h4 {
    height: 60px;
    color: #212121;
    font-family: Poppins;
    font-size: 21px;
    font-weight: 500;
    line-height: 30px;
    text-align: center;
  }
  .h4-ref-div {
    text-align: center;
    width: 404px !important;
    margin: auto;
    @media screen and (max-width: 600px) {
      width: 300px !important;
    }
  }
  .ref-code-div {
    margin: auto;
    width: 166px;
    height: 51px;
    border-radius: 12px;
    background: #fcefe0;
  }
  .h4-ref-code {
    height: 24px;
    color: #ff8502;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    padding-top: 12px;
  }
  .img-ref {
    margin-left: 15px;
  }
  .ref-h4-2 {
    height: 24px;
    color: #777777;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding-bottom: 32px;
    padding-top: 13px;
  }
  .multiline-text {
    .MuiOutlinedInput-multiline {
      padding: 9.5px 1px;
      overflow-wrap: anywhere;
    }
  }
  .profile-require {
    margin: 0;
    font-size: 0.75rem;
    margin-top: 3px;
    text-align: left;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    color: #f44336;
    width: max-content;
  }
  .menuButton {
    margin-left: auto !important;
  }
  [data-reach-menu-list],
  [data-reach-menu-items] {
    display: block !important;
    white-space: nowrap !important;
    border: none !important;
    //background: hsla(0, 100%, 100%, 0.99);
    outline: none !important;
    /* padding: 1rem 0; */
    font-size: 0% !important;
    width: 150px !important;
    // height: 82px !important;
    border-radius: 12px !important;
    background: #ffffff !important;
    padding: 1rem 0 !important;
    box-shadow: 0px 2px 7px rgba(195, 195, 195, 0.5) !important;
  }
}
.copy-p {
  color: green;
  margin: 0;
  font-size: 14px;
  margin-top: 3px;
  // text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 550;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}
.react-tel-input .flag-dropdown {
  border-radius: 10px 0px 0px 10px !important;
  height: 51px;
}
.react-tel-input .form-control {
  height: 51px !important;
}
.footer-space {
  margin-left: 250px;
  padding-left: 30px;
  @media screen and (max-width: 600px) {
    margin-left: -20px;
  }
}
