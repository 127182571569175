.side-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.side-header .MuiList-root {
  margin: 0px !important;
}
.side-header .MuiList-root .MuiListItem-gutters {
  padding-left: 20px;
  @media screen and (max-width: 600px) {
    padding-left: 10px;
  }
}
.title-line {
  //
  span {
    //   width: 133px;
    height: 30px;
    color: #212121;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
  }
}
.img-close {
  width: 16px;
  height: 16px;
}
.MuiDrawer-paperAnchorRight {
  left: auto;
  right: 0;
  width: 500px !important;
  // @media screen and (min-width: 1500px) {
  //   width: 45% !important;
  // }
  height: 100vh !important;
  background: #ffffff !important;
  box-shadow: 0px 8px 10px -5px rgb(0 0 0 / 20%),
    0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%) !important;
  .MuiBox-root {
    width: 500px !important;
  }
  @media screen and (max-width: 600px) {
    width: 250px !important;
  }
}
.div-close {
  margin-left: auto;
  margin-top: 10px;
  margin-right: 35px;
  cursor: pointer;
}
.order-detail-div {
  width: 440px;
  height: 110px;
  border-radius: 8px;
  border: solid 1.5px #f0f0f0;
  background: #ffffff;
  margin: auto;
  @media screen and (max-width: 600px) {
    width: 230px;
  }
}
.detail-list {
  //width: 92px;
  height: 21px;
  color: #212121;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: right;
  margin-left: auto;
  margin-right: 10px;
}
.title-list {
  margin-left: 10px;
  opacity: 0.7;
  height: 21px;
  color: #212121;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}
.item-title {
  width: 86px;
  height: 22px;
  color: #212121;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  margin-left: 30px;
  margin-top: 20px;
  @media screen and (max-width: 600px) {
    margin-left: 10px;
  }
}
.item-title-shipping {
  height: 22px;
  color: #212121;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  margin-left: 30px;
  margin-top: 20px;
  @media screen and (max-width: 600px) {
    margin-left: 10px;
  }
}
.item-Delivery {
  width: 440px;
  // max-height: 246px;
  // overflow-y: scroll;
  border-radius: 8px;
  border: solid 1.5px #f0f0f0;
  background: #ffffff;
  margin: auto;
  padding: 10px;
  // overflow-y: auto !important;
  @media screen and (max-width: 600px) {
    width: 230px;
    .MuiTypography-body1 {
      font-size: 12px;
    }
  }
}
.item-title-h4 {
  color: #ff8502;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  text-align: justify;
  margin-bottom: 15px;
  @media screen and (max-width: 600px) {
    width: 230px;
  }
}

.div-item {
  display: flex;
}
.detail-item-div {
  width: 156px;
  height: 42px;
  color: #212121;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin-left: 10px;
}
.value-item {
  margin-left: auto;
  height: 15px;
  color: #212121;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 15px;
  text-align: justify;
}
.qty-line {
  opacity: 0.8;
  width: 45px;
  height: 15px;
  color: #212121;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 300;
  line-height: 15px;
  text-align: justify;
}
.hr-line {
  //width: 410px;
  height: 1px;
  background: rgb(240, 240, 240);
  margin: 15px;
}
.item-shipping {
  width: 443px;
  height: auto;
  max-height: 126px;
  overflow-y: scroll;
  border-radius: 8px;
  border: solid 1.5px #f0f0f0;
  background: #ffffff;
  margin: auto;
  padding: 10px;
  overflow-y: auto !important;
  @media screen and (max-width: 600px) {
    margin: 10px;
    width: 230px;
  }
}
.item-shipping-h4 {
  width: 398px;
  // height: 96px;
  color: #212121;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  @media screen and (max-width: 600px) {
    width: auto;
  }
}
.item-payment {
  width: 440px;
  max-height: 234px;
  height: auto;
  overflow-y: scroll;
  border-radius: 8px;
  border: solid 1.5px #f0f0f0;
  background: #ffffff;
  margin: auto;
  padding: 10px;
  overflow-y: auto !important;
  @media screen and (max-width: 600px) {
    width: 230px;
  }
}
.item-payment-h4 {
  width: 398px;
  // height: 96px;
  color: #212121;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  // margin-left: 10px;
  @media screen and (max-width: 600px) {
    width: auto;
  }
}
.item-title-payment {
  height: 22px;
  color: #212121;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  //margin-left: 10px;
  margin-top: 10px;
}
.order-detail-pay-div {
  width: 440px;
  height: 194px;
  border-radius: 8px;
  border: solid 1.5px #f0f0f0;
  background: #ffffff;
  margin: auto;
  @media screen and (max-width: 600px) {
    width: 230px;
  }
}
.title-list-pay {
  margin-left: 10px;
  opacity: 0.7;
  height: 21px;
  color: #212121;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin-bottom: 0;
}
.title-list-pay-last {
  width: 89px;
  height: 24px;
  color: #212121;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-left: 10px;
}
.main-div {
  overflow-y: auto !important;
}
.detail-list-total {
  height: 24px;
  color: #212121;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: right;
  margin-left: auto;
  margin-right: 10px;
}
.status-Shipped-Change {
  height: 90px;
  border-radius: 12px;
  background: #5d16ab;
  margin-top: 34px;
  padding-left: 10px;
  padding-right: 10px;
  .MuiSelect-select.MuiSelect-select {
    padding-right: 24px;
    //width: 67px;
    height: 17px;
    color: #ffffff;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 17px;
    padding: 10px;
    text-align: initial;
  }
  .MuiSelect-icon {
    top: calc(50% - 12px);
    /* color: rgb(255 255 255 / 54%); */
    right: 0;
    position: absolute;
    color: #ffffff;
    pointer-events: none;
  }
}

.status-New-Change {
  height: 90px;
  border-radius: 12px;
  background: #ff8502;
  margin-top: 34px;
  padding-left: 10px;
  padding-right: 10px;
  .MuiSelect-select.MuiSelect-select {
    padding-right: 24px;
    //width: 67px;
    height: 17px;
    color: #ffffff;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 17px;
    padding: 10px;
    text-align: initial;
  }
  .MuiSelect-icon {
    top: calc(50% - 12px);
    /* color: rgb(255 255 255 / 54%); */
    right: 0;
    position: absolute;
    color: #ffffff;
    pointer-events: none;
  }
}
.status-Processing-Change {
  height: 90px;
  border-radius: 12px;
  background: #17b8eb;
  margin-top: 34px;
  padding-left: 10px;
  padding-right: 10px;
  .MuiSelect-select.MuiSelect-select {
    padding-right: 24px;
    //width: 67px;
    height: 17px;
    color: #ffffff;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 17px;
    padding: 10px;
    text-align: initial;
  }
  .MuiSelect-icon {
    top: calc(50% - 12px);
    /* color: rgb(255 255 255 / 54%); */
    right: 0;
    position: absolute;
    color: #ffffff;
    pointer-events: none;
  }
}
.status-Completed-Change {
  height: 90px;
  border-radius: 12px;
  background: #1aa31e;
  margin-top: 34px;
  padding-left: 10px;
  padding-right: 10px;
  .MuiSelect-select.MuiSelect-select {
    padding-right: 24px;
    //width: 67px;
    height: 17px;
    color: #ffffff;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 17px;
    padding: 10px;
    text-align: initial;
  }
  .MuiSelect-icon {
    top: calc(50% - 12px);
    /* color: rgb(255 255 255 / 54%); */
    right: 0;
    position: absolute;
    color: #ffffff;
    pointer-events: none;
  }
}
.status-OnHold-Change {
  height: 90px;
  border-radius: 12px;
  background: #9ea7ad;
  margin-top: 34px;
  padding-left: 10px;
  padding-right: 10px;
  .MuiSelect-select.MuiSelect-select {
    padding-right: 24px;
    //width: 67px;
    height: 17px;
    color: #ffffff;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 17px;
    padding: 10px;
    text-align: initial;
  }
  .MuiSelect-icon {
    top: calc(50% - 12px);
    /* color: rgb(255 255 255 / 54%); */
    right: 0;
    position: absolute;
    color: #ffffff;
    pointer-events: none;
  }
}
.status-Failed-Change {
  height: 90px;
  border-radius: 12px;
  background: #dd1a1a;
  margin-top: 34px;
  padding-left: 10px;
  padding-right: 10px;
  .MuiSelect-select.MuiSelect-select {
    padding-right: 24px;
    //width: 67px;
    height: 17px;
    color: #ffffff;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 17px;
    padding: 10px;
    text-align: initial;
  }
  .MuiSelect-icon {
    top: calc(50% - 12px);
    /* color: rgb(255 255 255 / 54%); */
    right: 0;
    position: absolute;
    color: #ffffff;
    pointer-events: none;
  }
}
.list-div {
  overflow-y: auto !important;
}
.drop-Down {
  width: 100% !important;
  height: 50px !important;
  border-radius: 6px !important;
  border: solid 1.5px #ebebeb;
  background: rgba(255, 255, 255, 0.1);
  margin-top: 20px;
}
.new-status-drop {
  // border-radius: 8px;
  background: #ff8502;
  // width: 137px !important;
  // height: 40px !important;
  color: #ffffff !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  // text-align: center !important;
  text-transform: capitalize !important;
  .MuiSelect-icon {
    color: #ffffff !important;
  }
}
.processing-status-drop {
  // border-radius: 8px;
  background: #17b8eb;
  // width: 137px !important;
  // height: 40px !important;
  color: #ffffff !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  // text-align: center !important;
  text-transform: capitalize !important;
  .MuiSelect-icon {
    color: #ffffff !important;
  }
}
.shipped-status-drop {
  // border-radius: 8px;
  background: #5d16ab;
  //width: 137px !important;
  //height: 40px !important;
  color: #ffffff !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  //text-align: center !important;
  text-transform: capitalize !important;
  .MuiSelect-icon {
    color: #ffffff !important;
  }
}
.completed-status-drop {
  //border-radius: 8px;
  background: #1aa31e;
  // width: 137px !important;
  // height: 40px !important;
  color: #ffffff !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  // text-align: center !important;
  text-transform: capitalize !important;
  .MuiSelect-icon {
    color: #ffffff !important;
  }
}
.on_hold-status-drop {
  //border-radius: 8px;
  background: #9ea7ad;
  // width: 137px !important;
  // height: 40px !important;
  color: #ffffff !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  // text-align: center !important;
  text-transform: capitalize !important;
  .MuiSelect-icon {
    color: #ffffff !important;
  }
}
.failed-status-drop {
  // border-radius: 8px;
  background: #dd1a1a;
  // width: 137px !important;
  // height: 40px !important;
  color: #ffffff !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  // text-align: center !important;
  text-transform: capitalize !important;
  .MuiSelect-icon {
    color: #ffffff !important;
  }
}
