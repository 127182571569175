.container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    font-family: "Poppins", sans-serif;
    .banner {
      width: 50%;
      position: relative;
      @media screen and (max-width:991px) {
        width: 30%;
      }
      @media screen and (max-width:540px) {
        width: 100%;
    }
      .logo {
        @media screen and (max-width:768px) {
          left: 30px;
      }
     
        top: 50px;
        left: 50px;
        position: absolute;
        object-fit: fill;
        z-index: 99;
        width:clamp(100px, 70%, 240px)
      }
      .banner-img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        position: relative;
        @media screen and (max-width:540px) {
          height: 150px;
      }
      }
      .rights-reserved {
        opacity: 0.85;
        position: absolute;
        left: 50px;
        bottom:30px;
        color: #ffffff;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 500;
        text-align: left;
        @media screen and (max-width:991px) {
          left: 30px;
      }
      @media screen and (max-width:540px) {
        bottom:10px;
      }
      }
    }
    .auth-form-wrapper {
      width: 50%;
      @media screen and (max-width:991px) {
        width: 70%;
    }
    @media screen and (max-width:540px) {
      width: 100%;
  }
      .auth-form {
        position: relative;
        margin: 10px;
      }
      .signup-link {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 100%;
        text-align: right;
        @media screen and (max-width:540px) {
          text-align: center;
          right: auto;
      }
        a {
          color: orange;
          text-decoration: none;
          font-size: 16px;
          font-weight: 600;
        }
      }
      .login-form {
        @media screen and (max-width:768px) {
          width: 80%;
      }
     
        width: 70%;
        margin: 0 auto;
        padding-top: 100px;
        .MuiFormControl-root {
          padding: 5px 0;
          width: 100%;
          .MuiOutlinedInput-root {
            width: 100% !important;
            border-radius: 12px !important;
          }
        }
        input {
          border-radius: 15px;
          height: 48px;
          width: 100%;
        }
        .input-lable {
          font-weight: 500px;
        }
        .welcome {
          font-style: normal;
          font-weight: 600;
          font-size: 36px;
          line-height: 54px;
          /* identical to box height */
          color: #212121;
          @media screen and (max-width:768px) {
            font-size: 32px;
        }
        }
      }
      .remember-me-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        .remember-checkbox {
          color: orange;
        }
        .forgot-password {
          color: orange;
          text-decoration: none;
          font-size: 16px;
          font-weight: 600;
        }
      }
      .login-button {
        width: 50% !important;
        background-color: #2d752f;
        color: white;
        background: linear-gradient(90deg, #2d752f 0%, #9ec955 100%);
        background-blend-mode: normal;
        height: 56px;
        display: flex;
        justify-content: space-evenly;
        border-radius: 12px;
        .loader {
          width: 10px;
          height: 10px;
          color: white;
          font-size: 10px;
        }
        span {
          color: #ffffff;
          font-family: Poppins;
          font-size: 16px;
          font-weight: 500;
          line-height: 16px;
          text-align: center;
          text-transform: uppercase;
        }
      }
      .divider-section {
        display: flex;
        justify-content: space-between;
        align-content: center;
        align-items: center;
        p {
          color: grey;
          font-size: 15px;
        }
        .divider {
          width: 43%;
          color: black;
        }
      }
    }
  } 
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active
  {
    -webkit-text-fill-color: #000 !important;
    transition: background-color 5000s ease-in-out 0s !important;
  }
  .docs-line{
    color: #212121;
    font-family: Poppins;
    font-size: 36px;
    font-weight: 600;
    line-height: 54px;
  
  }
  .provide-line{
    opacity: 0.8;
    height: 27px;
    color: #212121;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
  
  }
  .step-line{
    width: 94px;
    height: 27px;
    color: #ff8502;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
  
  }
  .bank-detail-kyc-line{
    height: 33px;
    color: #212121;
    font-family: Poppins;
    font-size: 22px;
    font-weight: 600;
    line-height: 33px;
  
  
  }
  .banke-details{
    color: #212121;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
  
  }
  .upload-line{
    height: 21px;
    color: #212121;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;  
  }
  
  .DocsImg{
    width: 520px !important;
    height: 220px !important;
    border-radius: 12px !important;
  
  }
  .MuiDropzoneArea-root {
      width: 500px !important;
      height: 220px !important;
      border-radius: 12px !important;
   // width: 100% !important;
    border: dashed;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    min-height: 170px !important;
    border-color: #ff8502 !important;
    border-radius: 4px !important;
    background-color: #fff !important;
}
.MuiDropzoneArea-icon {
   display: none !important;
}
.dropzone-text-div{
    
  height: 13px;
  color: #ff8502;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  
  text-align: center;
}
.MuiDropzoneArea-text {
     margin-top: 65px !important; 
     margin-bottom: 0px !important; 
   // position: absolute !important; 
     font-size: small !important; 
     color: orange !important; 
}
.MuiOutlinedInput-input{
    padding: 1.5px 15px !important;
}
.cross-circle-cancel{
  position: absolute !important;
  //width: 139% !important;
  left: 610px !important;
  height: 30px !important;
  color: #e42f2f;
  margin-top: -15px;
  @media screen and (max-width:540px) {
    position: absolute !important;
  width: 5% !important;
  height: 30px !important;
  color: #e42f2f;
    left: 437px;
}
@media screen and (max-width:375px) {
  position: absolute !important;
width: 5% !important;
height: 35px !important;
color: #e42f2f;
  left: 300px;
}
}
.pdf-class{
  width: 100% !important;
  height: 220px !important;
  border-radius: 12px !important;
  
    @media screen and (max-width:991px) {
      width: 100% !important;
    }
    @media screen and (max-width:540px) {
      width: 100% !important;
}
}
