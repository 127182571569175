.MuiPaper-elevation4 {
  box-shadow: none !important;
}
.profile-img {
  width: 42px;
  height: 42px;
  border-radius: 30px !important;
}
.div-profile {
  color: black;
  display: flex;
  cursor: pointer;
  text-transform: capitalize;
  position: relative;
}
.user-line {
  color: #212121;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
}
.account-line {
  opacity: 0.7;
  width: 57px;
  height: 18px;
  color: #212121;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin: 0;
}
.hello {
  position: relative;
  right: 0;
}
.das-heading {
  color: #212121;
  font-family: Poppins;
  font-size: 26px;
  font-weight: 600;
  margin-left: 30px;
}

.profile-img {
  width: 42px;
  height: 42px;
  border-radius: 30px;
  @media screen and (max-width: 1070px) {
    width: 22px;
    height: 22px;

    border-radius: 30px;
  }
}
.profile-line {
  @media screen and (max-width: 600px) {
    display: none;
  }
}
.div-profile {
  @media screen and (min-width: 600px) {
    color: black;
    display: flex;
    cursor: pointer;
    text-transform: capitalize;
  }
  @media screen and (max-width: 600px) {
    padding-left: 10px;
    width: 60px;
    height: 25px;
  }
}

.user-line {
  color: #212121;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
  @media screen and (max-width: 1070px) {
    display: none;
  }
}
.account-line {
  opacity: 0.7;
  width: 57px;
  height: 18px;
  color: #212121;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin: 0;
  @media screen and (max-width: 1070px) {
    display: none;
  }
}
.das-heading {
  color: #212121;
  font-family: Poppins;
  font-size: 26px;
  font-weight: 600;
  margin-left: 30px;
  @media screen and (max-width: 600px) {
    margin-left: 0px;
    font-size: 15px;
  }
}
.MuiBadge-root {
  display: inline-flex !important;
  position: relative !important;
  flex-shrink: 0 !important;
  vertical-align: middle !important;
  margin-left: 20px !important;
  // background: #ff8502 !important;
}
.MuiBadge-colorSecondary {
  color: #fff !important;
  background-color: #ff8502 !important;
}
.MuiBadge-badge {
  height: 20px;
  display: flex;
  padding: 0 6px;
  z-index: 1;
  position: absolute;
  flex-wrap: wrap;
  font-size: 0.75rem;
  min-width: 20px;
  box-sizing: border-box;
  transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  align-items: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 600 !important;
  line-height: 1;
  align-content: center;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
  padding: 10px !important;
}
// .input-search {
//   padding: 18.5px 14px !important;
// }
// .change-input div .MuiInputBase-input {
//   padding: 0px !important;
// }
// .change-input div .MuiInputBase-input {
//   padding: 0px !important;
//   position: relative;
// }
.MuiOutlinedInput-adornedStart {
  padding-left: 14px !important;
  padding: 8px !important;
}
.input-searrch {
  position: relative !important;
  width: 264px !important;
  height: 40px !important;
  border-radius: 12px !important;
  border: solid 1px #e4e4e4 !important;
  background: #ffffff !important;
  margin-left: auto !important;

  @media screen and (max-width: 652px) {
    position: relative !important;
    width: 100% !important;
    padding-left: 20px !important;
    height: 40px !important;
    border-radius: 12px !important;
    border: solid 1px #e4e4e4 !important;
    background: #ffffff !important;
    margin-left: 12px !important;
  }
  @media screen and (min-width: 680px) and (max-width: 820px) {
    position: relative !important;
    padding-left: 190px !important;
    width: 100% !important;
    height: 40px !important;
    border-radius: 12px !important;
    border: solid 1px #e4e4e4 !important;
    background: #ffffff !important;
    margin-left: 12px !important;
  }
  // @media screen and (max-width: 1070px) {
  //   position: relative !important;
  //   padding-left: 20px !important;
  //   width: 100% !important;
  //   height: 40px !important;
  //   border-radius: 12px !important;
  //   border: solid 1px #e4e4e4 !important;
  //   background: #ffffff !important;
  //   margin-left: 12px !important;
  // }
  // margin-left: 378px !important;
  // margin-top: 21px !important;
}
.input-search-product {
  background: #ffffff !important;
  border: none !important;
}
.new-product {
  width: 190px !important;

  @media screen and (max-width: 1130px) {
    width: 120px !important;
  }
  height: 40px !important;
  background: #ff8502 !important;
  border-radius: 8px !important;
  span {
    width: 158px;
    height: 16px;
    color: #ffffff;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
  }
}
.new-product-icon {
  width: 10px;
  height: 30px !important;
  background: #ff8502 !important;
  border-radius: 8px !important;
  left: 10px;
}
.clear-all {
  //width: 167px;
  height: 24px;
  color: #ff9938;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  margin-left: 20px;
  cursor: pointer;
  @media screen and (max-width: 600px) {
    font-size: 8px;
    line-height: 14px;
    margin-left: 4px;
  }
}

.das-heading-notifiaction {
  color: #212121;
  font-family: Poppins;
  font-size: 26px;
  font-weight: 600;
  margin-left: 30px;
  @media screen and (max-width: 600px) {
    font-size: 14px;
    margin-left: 0px;
  }
}
.notification-space {
  padding: 16px;
  cursor: pointer;
  @media screen and (max-width: 400px) {
    padding: 0px;
  }
  .MuiBadge-root {
    margin-left: 0px !important;
  }
  .MuiBadge-badge {
    padding: 4px !important;
    border: 1px solid white;
    border-radius: 5px !important;
  }
}

.MuiBox-root-8 {
  min-width: 0 !important;
}
.order-div-nav {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  .MuiBadge-root {
    margin-left: 30px !important;
  }
  @media screen and (max-width: 652px) {
    display: inline-flex;
    // margin-left: 20px !important;
    align-items: center;
    justify-content: space-between;
  }
}
.app-bar-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 250px);
  margin-left: 250px;
  @media screen and (max-width: 600px) {
    margin-left: 10px;
    // width: 100%;
    .MuiToolbar-gutters {
      padding-left: 0px;
    }
  }
  @media screen and (max-width: 400px) {
    .MuiToolbar-gutters {
      padding-left: 10px;
    }
  }
  .MuiIconButton-edgeStart {
    margin-left: 12px;
  }
  .show-icon {
    @media screen and (min-width: 600px) {
      display: none;
    }
  }
}
.MuiOutlinedInput-notchedOutline {
  // border: none !important;
}
.language-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  img {
    height: 20px;
    width: 20px;
  }
}
.das-terms-condition {
  color: #212121;
  font-family: Poppins;
  font-size: 26px;
  font-weight: 600;
  margin-left: 30px;
  // margin-right: 540px;
  @media screen and (max-width: 600px) {
    margin-left: 0px;
    font-size: 15px;
    margin-right: 20px;
  }
}
.product-search {
  @media screen and (min-width: 850px) {
    margin-right: 0px;
    display: flex;
  }
  display: none;
  .input-web-search {
    width: 264px !important;
    height: 40px !important;
    margin-right: 20px;
    border: solid 1px #e4e4e4 !important;
    border-radius: 12px;
    background: #ffffff;
  }
}
.order-search {
  @media screen and (max-width: 820px) {
    position: absolute;
    top: 20px;
    margin: 30px 10px;
  }
}
.product-search-icon {
  @media screen and (max-width: 599px) {
    position: absolute;
    top: 50px;
    display: flex;
    margin-left: 10px;
    width: 80%;
    font-size: 20px;
    justify-content: center;
    align-items: center;
    .input-width {
      width: 80%;
    }
  }
  @media screen and (min-width: 600px) and (max-width: 900px) {
    position: absolute;
    top: 20px;
    display: flex;
    margin-top: 40px;
    margin-left: 30px;
    .input-width {
      width: 450px;
    }
  }
  display: none;
}

.profile-dropdowns {
  position: absolute;
  min-width: 150px;
  top: 65px;
  right: 10px;
  background-color: #eee;
  border-radius: 8px;
  box-shadow: -1px 1px 20px 0px rgba(146, 146, 146, 0.75);
  z-index: 10;
  @media screen and (max-width: 600px) {
    top: 40px;
    right: 0px;
  }
}

.dropdown-tab {
  padding: 1px;
  border-bottom: 1px solid #d1d1d1af;
}

.dropdown-tab:last-child {
  border-bottom: none;
}

.dropdown-text {
  color: #212121;
  font-size: 16px;
  font-family: Poppins;
  margin: 10px;
}

.logout-text {
  color: #ca2d1c;
}

.overlay2 {
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 9;
}
