.Banner {
  position: relative;
  width: 100%;
}
.banner-heading-text {
  font-size: 52px !important;
  font-weight: 700 !important;
  color: #fff;
  text-shadow: 0px 2px 14px rgba(0, 0, 0, 0.09);
  @media screen and (max-width: 600px) {
    font-size: 35px !important;
  }
}

.banner-disc-text {
  font-size: 26px !important;
  font-weight: 600 !important;
  color: #fff;
  text-shadow: 0px 2px 14px rgba(0, 0, 0, 0.09);
}

.banner-btn {
  font-size: 14px !important;
  font-weight: 500 !important;
  background-color: #fff !important;
  width: 150px;
  height: 50px;
  margin: 10px 0 !important;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
  border-radius: 10px !important;
  // padding: 10px !important;
  @media screen and (max-width: 600px) {
    margin: 10px 10px !important;
    width: 120px;
  }
}

.banner-tc-text {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #fff;
  text-shadow: 0px 2px 14px rgba(0, 0, 0, 0.09);
}

.banner-content {
  top: 120px;
  left: 140px;
  position: absolute;
  @media screen and (max-width: 600px) {
    top: 160px;
    left: 80px;
  }
  @media screen and (max-width: 370px) {
    left: 60px;
  }
}

.crousal {
  min-height: 450px !important;
}

.section-buyer {
  margin: 50px 30px;
}

.section-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .top-products {
    font-size: 32px;
    font-weight: 600;
    @media screen and (max-width: 600px) {
      font-size: 20px;
    }
  }
}

.prod-section {
  position: relative;
  // width: 100%;
  // padding: 10px 30px;
  // margin-top: 10px !important;
  // display: flex;
  // justify-content: space-between;
  // flex-wrap: nowrap;
  // overflow: hidden;
}

.wrapper::-webkit-scrollbar,
.wrapper1::-webkit-scrollbar {
  display: none;
}
.wrapper {
  vertical-align: middle;
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  transition: 1s;
}

.wrapper1 {
  vertical-align: middle;
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  transition: 1s;
}

.prod-card {
  margin: 20px 10px;
  min-width: 20%;
  display: inline-block;
  cursor: pointer;
}

.card-prod-name {
  font-size: 16px !important;
  font-weight: 400 !important;
  font-family: Poppins !important;
}
.card-prod-quan {
  font-size: 16px !important;
  font-weight: 400 !important;
  margin: 5px 0 !important;
  color: #aaa !important;
  font-family: Poppins !important;
}
.card-prod-price {
  font-size: 18px !important;
  font-weight: 600 !important;
  font-family: Poppins !important;
}
.featured-products {
  font-size: 32px;
  font-weight: 600;
}
.view-all-btn {
  color: #2d752f !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  border: 1px solid #2d752f !important;
  border-radius: 10px !important;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15) !important;
  width: 130px !important;
  cursor: pointer;
  @media screen and (max-width: 600px) {
    font-size: 12px;
  }
}

.feat-prod-container {
  margin-top: 50px !important;
}

.right_arrow {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 0;
  z-index: 12;
  bottom: calc((400px - 30px) / 2);
  cursor: pointer;
}

.left_arrow {
  width: 30px;
  height: 30px;
  position: absolute;
  left: 0;
  z-index: 11;
  bottom: calc((400px - 30px) / 2);
  cursor: pointer;
}

.footer {
  padding: 60px 40px 20px 30px;
  @media screen and (max-width: 600px) {
    padding: 0px;
  }
}

.footer-row1,
.footer-row2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
}

.footer-row2 {
  margin-top: 30px;
  @media screen and (max-width: 600px) {
    margin-top: 10px;
  }
}
.icon-space {
  margin-top: 10px;
}
.social-logo {
  width: 20px;
  height: 20px;
  margin-left: 15px;
  cursor: pointer;
}

.footer-text-link,
.rights-text {
  font-size: 16px !important;
  font-family: Poppins !important;
  color: #888 !important;
  margin-left: 20px !important;
  cursor: pointer;
}

.rights-text {
  font-size: 14px !important;
  cursor: default;
}
.slides {
  // position: relative;
  .slick-prev {
    position: absolute;
    top: 40%;
  }

  .slick-next {
    position: absolute;
    top: 40%;
  }
  .slick-prev {
    left: 0%;
    z-index: 1;
  }
  .slick-next {
    right: 1%;
    z-index: 0;
  }
  .slick-prev:before,
  .slick-next:before {
    color: #de7812 !important;
    font-size: 25px;
    font-weight: 600;
    // margin-left: -10px;
  }
  // .slider .slick-list .slick-track {
  //   width: 100%;
  //   display: flex !important;
  //   justify-content: start !important;
  // }
  // .slick-slide {
  //   width: 300px !important;
  // }
  // .slick-slider {
  //   position: relative;
  //   height: 100%;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   padding: 0 20px;
  // }
  // .slick-list {
  //   position: relative;
  //   display: block !important;
  //   overflow: hidden;
  //   padding: 100px !important;
  //   width: 100%;
  // }
  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    margin: 0px;
  }
  // .slick-slide div {
  //   display: flex !important;
  //   flex-direction: column;
  //   justify-content: center;
  //   align-items: center;
  // }
  // .slick-slide .image {
  //   object-fit: cover;
  //   border-radius: 2px;
  //   min-width: 150px !important;
  //   width: 80%;
  // }
  // .slick-slide div div span {
  //   margin-top: 10px;
  //   width: inherit;
  //   text-align: center;
  //   font-size: 0.75rem;
  //   font-family: Arial, Helvetica, sans-serif;
  //   font-weight: 600;
  //   text-transform: uppercase;
  //   color: #111;
  // }

  // .slick-slide:not(.slick-active) {
  //   position: relative;
  //   z-index: 997;
  // }
  // .slick-slide.slick-active:not(.slick-current) {
  //   position: relative;
  //   z-index: 998;
  //   transform: scale(1.3);
  //   transition: 0.25s;
  // }
  // .slick-slide.slick-active.slick-current {
  //   position: relative;
  //   z-index: 999;
  //   transform: scale(1.6);
  //   transition: 0.25s;
  // }
}
