.profile-field-con {
  width: 50%;
  margin-bottom: 20px;
  position: relative;
  @media screen and (max-width: "900px") {
    width: 90%;
  }
}

.profile-field {
  width: 100% !important;
}

.react-tel-input,
.form-control {
  width: 100% !important;
  border-radius: 12px !important;
}

.profile-save-btn {
  width: 50%;
  height: 56px !important;
  background: linear-gradient(90deg, #2d752f 0%, #9ec955 100%) !important;
  color: #fff !important;
  margin-top: 40px !important;
  border: none !important;
  border-radius: 12px !important;
  font-weight: 500 !important;
  @media screen and (max-width: "900px") {
    width: 90%;
  }
}

.div-profile-img1 {
  width: 138px;
  height: 138px;
  background: white;
  position: relative;
  border-radius: 50%;
  margin: 0 0 30px 0;
  @media screen and (max-width: 600px) {
    margin: 0 0 30px 80px;
  }
}

.send_otp_btn {
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  top: 40px;
  right: 10px;
  cursor: pointer;
}

.send_otp_btn_dis {
  font-size: 14px;
  font-weight: 400;
  position: absolute;
  top: 40px;
  right: 10px;
  cursor: not-allowed;
}
