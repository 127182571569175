.home-content {
  position: relative;
  width: calc(100% - 250px);
  margin-left: 250px;
  @media screen and (max-width: 600px) {
    width: 100%;
    margin-left: 0px;
  }
}

.overview-boxes {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 20px;
  margin-bottom: 26px;
  .box {
    background-color: white;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    width: calc(100% / 4 - 15px);
    height: 120px;
    padding: 0px 24px;
    border-radius: 12px;
    border-left: #f5b041 solid 5px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    @media screen and (max-width: 1080px) {
      width: calc(100% / 2 - 10px);
      padding: 15px 10px;
      margin: 5px;
    }
    @media screen and (max-width: 600px) {
      width: 100%;
      padding: 15px 10px;
      margin: 5px;
    }
    .box-header {
      font-size: 20px;
      font-weight: 400;
      width: 200px;
      color: #212121;
      font-family: Poppins;
      font-size: 18px;
      font-weight: 400;
      opacity: 0.8;
      @media screen and (max-width: 1080px) {
        font-size: 15px;
        font-weight: 400;
      }
    }
    .number {
      font-size: 28px;
      // font-weight: 600;
      width: 118px;
      color: #212121;
      font-family: Poppins;
      @media screen and (max-width: 1080px) {
        font-size: 15px;
        font-weight: 400;
      }
    }
  }
}
.main {
  display: flex;
  justify-content: space-between;
  // align-items: center;
  margin: 20px 20px 0 20px;
  @media screen and (max-width: 600px) {
    flex-direction: column;
    width: calc(100% - 45px);
    // padding: 0px 15px;
  }
}
.earning {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-radius: 12px;
  margin-right: 20px;
  background-color: white;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  width: 70%;
  height: 10%;
  @media screen and (max-width: 600px) {
    width: 100%;
    margin-bottom: 26px;
  }
  .earning-title {
    width: 80%;
    font-size: 22px;
    padding-top: 20px;
    padding-left: 20px;
    font-weight: 400;
    @media screen and (max-width: 1200px) {
      width: 50%;
      font-size: 15px;
      padding-left: 15px;
    }
  }
  .earning-period {
    padding-top: 20px;
    padding-right: 20px;
    .MuiOutlinedInput-input {
      margin: 10px 4px !important;
      border-radius: 0px !important;
      padding: 0px 24px !important;
    }
  }
  .earning-chart {
    padding-left: 10px;
    width: 100%;
    @media screen and (max-width: 600px) {
      padding-left: 0px;
    }
  }
}
.top-sold {
  width: 30%;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 12px;
  // height: 10%;
  // padding: 20px 20px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  @media screen and (max-width: 700px) {
    width: 100%;
  }
  .recent-title {
    color: #212121;
    font-family: Poppins;
    font-size: 22px;
    font-weight: 600;
    padding-top: 20px;
    padding-left: 20px;
    @media screen and (max-width: 1160px) {
      font-size: 15px;
    }
    @media screen and (max-width: 930px) {
      font-size: 12px;
    }
  }
}
.empty-list {
  text-align: left;
  padding-left: 20px;
  padding-top: 10px;
  .product-fonts {
    font-family: Poppins;
    font-size: 17px;
    font-weight: 400;
    @media screen and (max-width: 930px) {
      font-size: 12px;
      margin-bottom: 10px;
    }
  }
}

.product-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: nowrap;

  .MuiListItem-gutters {
    padding-left: 10px;
  }
  .MuiListItem-padding {
    padding-left: 0px;
  }
  .list-text {
    margin: 0%;
    padding-left: 16px;
    color: #212121;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    // height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-wrap: wrap;
    @media screen and (max-width: 770px) {
      .MuiListItemText-primary {
        font-size: 12px;
      }
    }
    .product-quantity {
      color: #999999;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 300;
      height: 60%;
      @media screen and (max-width: 770px) {
        font-size: 10px;
      }
    }
    .sold-quantity {
      color: #212121;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      @media screen and (max-width: 770px) {
        font-size: 10px;
      }
    }
  }
}

.imgs {
  border-radius: 12px;
  width: 80px;
  height: 80px;
  @media screen and (max-width: 1200px) {
    width: 40px;
    height: 80px;
  }
}
.btns {
  width: 100%;
  .MuiButton-label {
    color: #58953d;
    font-weight: 600;
  }
}
.home-footer {
  @media screen and (min-width: 600px) {
    padding: 0 20px;
  }
}
