.product-detail-container {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  height: 530px;
  margin-top: 15px;
  @media screen and (max-width: 600px) {
    flex-direction: column;
    padding: 0 10px;
    height: auto;
  }
  .product-images-wrapper {
    width: 45%;
    height: 510px;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 600px) {
      flex-direction: column-reverse;
      width: 100%;
      height: auto;
    }
    .image-list {
      width: 20%;
      height: 530px;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 600px) {
        flex-direction: row;
        width: 100%;
        height: auto;
        // overflow-y: visible;
      }
      img {
        width: 120px;
        height: 120px;
        border-radius: 12px;
        border: 1px solid #979797;
        margin-bottom: 10px;
      }

      .active {
        border: 4px solid #ff8502 !important;
      }
    }

    .main-image {
      width: 80%;

      img {
        height: 100%;
        width: 100%;
        border-radius: 12px;
        border: 1px solid #979797;
        @media screen and (max-width: 600px) {
          height: 300px;
          // width: 50%;
        }
      }
    }
  }

  .product-detail-wrapper {
    width: 45%;
    @media screen and (max-width: 600px) {
      width: 100%;
      text-align: justify;
    }
    .product-title {
      max-height: 106px;
      display: flex;
      align-items: center;

      .product-name {
        max-width: 93%;
        color: #212121;
        font-family: Poppins;
        font-size: 36px;
        font-weight: 600;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .share-icon {
        background: #ff85021f;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        cursor: pointer;

        img {
          width: 20px;
          height: 20px;
          margin-right: 2px;
        }
      }
    }

    .quantity {
      p {
        color: #333333;
        font-family: Poppins;
        font-size: 20px;
        font-weight: 400;
        opacity: 0.7;
      }
    }

    .price {
      p {
        color: #212121;
        font-family: Poppins;
        font-size: 34px;
        font-weight: 500;
      }
    }

    .stock {
      width: auto;

      .in-stock {
        background: rgba(0, 128, 0, 0.11);
        font-family: Poppins;
        color: green;
        padding: 10px;
        width: fit-content;
        text-transform: uppercase;
        font-weight: 600;
        border-radius: 10px;
        font-size: 12px;
      }

      .out-of-stock {
        background: rgba(255, 0, 0, 0.112);
        font-family: Poppins;
        color: red;
        padding: 10px;
        width: fit-content;
        text-transform: uppercase;
        font-weight: 600;
        border-radius: 10px;
        font-size: 12px;
      }
    }

    .loading-button {
      margin: 30px 0px 30px 0px;

      button {
        width: 335px;
        height: 56px;
      }
    }

    .add-to-cart {
      margin: 30px 0px 30px 0px;

      button {
        width: 335px;
        height: 56px;
        background: linear-gradient(90deg, #2d752f 0%, #9ec955 100%);
        background-blend-mode: normal;
        color: white;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 500;
        border-radius: 10px;
      }
    }

    .added-to-cart {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-content: center;
      width: 400px;
      margin: 30px 0px 30px 0px;
      @media screen and (max-width: 600px) {
        width: 330px;
      }
      .remove {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 56px;
        height: 56px;
        border-radius: 4px;
        border: 1px solid #2d752f;
        color: #2d752f;
        background: #ffffff;
        // background-blend-mode: normal;
        cursor: pointer;
        z-index: 0;
      }

      .cart-added-text {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 150px;
        height: 56px;
        color: #333333;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
      }

      .add {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        width: 56px;
        height: 56px;
        border-radius: 8px;
        background: linear-gradient(90deg, #2d752f 0%, #9ec955 100%);
        background-blend-mode: normal;
        cursor: pointer;
      }
      .remove-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgba(255, 0, 0, 0.822);
        font-family: Poppins;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
      }
    }

    .description {
      .des-lable {
        color: #212121;
        font-family: Poppins;
        font-size: 18px;
        font-weight: 600;
      }

      .des-content {
        height: 180px;
        color: #212121;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 400;
        overflow-y: auto;
      }
    }
  }
}

.related-products-slider {
  //   margin: 0px 50px 0px 50px;
}

.product-breadcrumbs {
  color: #212121;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500 !important;
  letter-spacing: 0.11666667px;
  margin: 30px 0px 0px 50px;
  cursor: pointer;

  // :last-child {
  //   opacity: 0.5009533;
  //   cursor: default;
  // }
  .product-name {
    opacity: 0.5009533 !important;
    cursor: default !important;
  }
}

.disabled {
  cursor: not-allowed !important;
  background: #979797 !important;
}
