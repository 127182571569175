.MuiTabs-indicator {
  background-color: #ff8502 !important;
  width: 21px !important;
  height: 3px !important;
  border-radius: 1.5px !important;
  margin-left: 13px !important;
}
// .MuiTabs-fixed {
//   width: 100%;
//   margin-left: 250px;
//   @media screen and (max-width: 600px) {
//     margin-left: 0px;
//   }
// }
// .MuiBox-root-19 {
//   margin-left: 250px;
//   @media screen and (max-width: 600px) {
//     margin-left: 0px;
//   }
// }
.MuiTab-root {
  min-width: 0px !important;
}
.MuiTabs-flexContainer {
  display: flex !important;
  margin-left: 20px !important;
}
.MuiTab-textColorInherit.Mui-selected {
  opacity: 1;
  /* width: 20px; */
  height: 24px !important;
  color: #ff8502 !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
}
.MuiTab-wrapper {
  width: 100% !important;
  display: inline-flex !important;
  align-items: center !important;
  flex-direction: column !important;
  justify-content: center !important;
  text-transform: capitalize !important;
  //color: #838d80 !important;
}
.MuiTab-textColorInherit {
  color: #838d80 !important;
}

.new-status,
.processing-status,
.completed-status,
.failed-status,
.on_hold-status,
.shipped-status {
  border-radius: 8px;
  background: #ff8502;
  width: 137px !important;
  height: 40px !important;
  color: #ffffff !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  text-align: center !important;
  text-transform: capitalize !important;
  .MuiSelect-icon {
    color: #ffffff !important;
  }
}
.processing-status {
  background: #17b8eb;
}
.shipped-status {
  background: #5d16ab;
}
.completed-status {
  background: #1aa31e;
}
.on_hold-status {
  background: #9ea7ad;
}
.failed-status {
  background: #dd1a1a;
}
body .ReactTable .rt-tr-group {
  display: flex !important;
  flex-direction: row !important;
  align-items: initial !important;
  height: auto !important  ;
}
.table-box {
  //   width: calc(100% - 250px);
  margin-left: 250px;
  @media screen and (max-width: 600px) {
    margin-left: 0px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 768px) {
  .content {
    width: 100%;
  }
}
.ReactTable {
  padding-left: 20px;

}
.ReactTable .rt-tr {
  flex: 1 0 auto;
  display: inline-flex;
  // cursor: pointer;
}
.order-id-clickable {
  cursor: pointer;
}
.ReactTable .rt-tbody{
  overflow: initial !important;
  }