.cart-container {
  // width: 90%;
  margin: 30px;
  height: 100vh;
  @media screen and (max-width: 600px) {
    margin: 0px;
  }
  .stepper-container {
    display: flex;
    justify-content: center;
    align-content: center;

    .stepper {
      width: 588px;
      display: flex;
      justify-content: center;
      align-content: center;

      .step-wrapper {
        height: 92px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .icon {
          width: 64px;
          height: 64px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background: #e9e9e9;
          background-blend-mode: normal;
          border-radius: 50%;
          color: #9f9f9f;
        }

        .icon.active {
          border: 1px solid #ff8502;
          border-radius: 50%;
          color: #ff8502;
          background: white;
        }

        .icon.completed {
          border: 1px solid #ff8502;
          border-radius: 50%;
          color: white;
          background: #ff8502;
        }

        .step-name {
          color: #212121;
          font-family: Poppins;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }

  .view-as-per-step-container {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    @media screen and (max-width: 600px) {
      flex-direction: column;
      margin: 30px;
    }
    .cartitems-container {
      width: 70%;
      height: auto;
      max-height: 528px;
      border-radius: 12px;
      border: 2px solid #f0f0f0;
      background: #ffffff;
      background-blend-mode: normal;
      overflow: auto;
      @media screen and (max-width: 600px) {
        width: 100%;
      }
      .cartitems-count {
        color: #212121;
        font-family: Poppins;
        font-size: 18px;
        font-weight: 700;
        margin: 15px 30px;
      }

      .cartitems-list {
        margin: 0px 30px;

        .cartitem {
          width: 100%;
          margin: 10px 0px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .details-wrapper {
            display: flex;
            width: 100%;
            justify-content: flex-start;
            align-items: center;

            .image {
              width: 70px;
              height: 70px;
              border-radius: 8px;
              margin-right: 20px;
            }

            .details {
              width: 70%;

              .name {
                color: #212121;
                font-family: Poppins;
                font-size: 16px;
                font-weight: 500;
              }

              .quantity {
                color: #999999;
                font-family: Poppins;
                font-size: 14px;
                font-weight: 300;
              }

              .price {
                color: #212121;
                font-family: Poppins;
                font-size: 14px;
                font-weight: 500;
              }
            }

            .stock {
              width: auto;

              .out-of-stock {
                background: rgba(255, 0, 0, 0.112);
                font-family: Poppins;
                color: red;
                padding: 10px;
                width: fit-content;
                text-transform: uppercase;
                font-weight: 600;
                border-radius: 10px;
                font-size: 12px;
              }
            }
          }

          .actions-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 70px;

            .added-to-cart {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              width: 100px;
              height: 30px;

              .remove {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                height: 30px;
                border-radius: 4px;
                border: 1px solid #2d752f;
                color: #2d752f;
                background: #ffffff;
                cursor: pointer;
                z-index: 0;
              }

              .cart-added-text {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 30px;
                color: #333333;
                font-family: Poppins;
                font-size: 16px;
                font-weight: 500;
                text-align: center;
              }

              .add {
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                width: 30px;
                height: 30px;
                border-radius: 8px;
                background: linear-gradient(90deg, #2d752f 0%, #9ec955 100%);
                background-blend-mode: normal;
                cursor: pointer;
              }
            }

            .remove-wrapper {
              display: flex;
              justify-content: center;
              align-items: center;
              color: #6f6f6f;
              font-family: Poppins;
              font-size: 14px;
              font-weight: 500;
              cursor: pointer;
            }
          }
        }
      }
    }

    .address-container {
      width: 70%;
      height: 350px;
      background: #ffffff;
      border-radius: 12px;
      border: 2px solid #f0f0f0;
      background-blend-mode: normal;
      @media screen and (max-width: 600px) {
        width: 100%;
      }
      .saved-addre {
        padding: 10px 30px;
        color: #212121;
        font-family: Poppins;
        font-size: 18px;
        font-weight: 700;
      }
      .address-list {
        height: 210px;
        overflow-y: auto;
      }

      .address-detail {
        display: flex;
      }

      .checked-radio-shape {
        background: linear-gradient(90deg, #2d752f 0%, #9ec955 100%);
        margin-left: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        width: 24px;
        height: 24px;
        background-blend-mode: normal;
      }

      .unchecked-radio-shape {
        margin-left: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
      }

      .address-header {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 600;
        margin: 2px 10px;
      }

      .address-area {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        margin: 1px 10px;
      }

      .address-divider {
        margin: 15px 30px;
        width: 890px;
        height: 1px;
        background: #f0f0f0;
        background-blend-mode: normal;
      }
      .new-address {
        margin-left: 30px;
      }
    }

    .order-summary-container {
      width: 28%;
      height: 350px;
      border-radius: 12px;
      border: 2px solid #f0f0f0;
      background: #ffffff;
      background-blend-mode: normal;
      @media screen and (max-width: 600px) {
        width: 100%;
        margin-top: 10px;
      }
      .order-summary-wrapper {
        margin: 0px 30px;
        height: 333px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        .order-title {
          color: #212121;
          font-family: Poppins;
          font-size: 18px;
          font-weight: 700;
        }

        .order-row {
          display: flex;
          justify-content: space-between;

          .item-name {
            color: #212121;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 300;
          }

          .item-price {
            color: #212121;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 500;
            text-align: right;
          }
        }

        .order-total {
          display: flex;
          justify-content: space-between;

          .text {
            color: #212121;
            font-family: Poppins;
            font-size: 16px;
            font-weight: 400;
          }

          .price {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 700;
            text-align: right;
          }
        }

        .countinue-button {
          // width: 320px;
          height: 56px;
          color: #ffffff;
          font-family: Poppins;
          font-size: 14px;
          font-weight: 500;
          line-height: 16px;
          text-align: center;
          text-transform: uppercase;
          background: linear-gradient(90deg, #2d752f 0%, #9ec955 100%);
          background-blend-mode: normal;
          border-radius: 10px;
        }

        .stock {
          width: auto;

          .out-of-stock {
            background: rgba(255, 0, 0, 0.112);
            font-family: Poppins;
            color: red;
            padding: 10px;
            width: fit-content;
            text-transform: uppercase;
            font-weight: 600;
            border-radius: 10px;
            font-size: 12px;
          }
        }
      }
    }

    .payment-success-container {
      width: 100%;
      height: 70vh;
      padding: 50px;
      border-radius: 12px;
      border: 2px solid #f0f0f0;
      background: #ffffff;
      background-blend-mode: normal;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-direction: column;
      @media screen and (max-width: 600px) {
        padding: 10px;
      }
      .checked-icon {
        width: 80px;
        height: 80px;
      }
      .title {
        color: #212121;
        font-family: Poppins;
        font-size: 24px;
        font-weight: 600;
        letter-spacing: 0.2px;
      }
      .thank-you {
        color: #212121;
        font-family: Poppins;
        font-size: 18px;
        font-weight: 400;
        text-align: center;
      }
      .date-title {
        color: #212121;
        font-family: Poppins;
        font-size: 18px;
        font-weight: 300;
        text-align: center;
        letter-spacing: 0.15px;
      }
      .date {
        color: #212121;
        font-family: Poppins;
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        letter-spacing: 0.15px;
      }
      .shop-more-products {
        width: 220px;
        height: 56px;
        background: linear-gradient(90deg, #2d752f 0%, #9ec955 100%);
        background-blend-mode: normal;
        color: #ffffff;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 550;
        line-height: 16px;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 1.25px;
      }
    }
  }
}

.payment-options {
  width: 70%;
  height: 274px;
  border-radius: 12px;
  border: 2px solid #f0f0f0;
  background: #ffffff;
  background-blend-mode: normal;
  padding: 30px;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
  .payment-header {
    color: #212121;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 700;
  }

  .payment-radio-group {
    display: flex;
    flex-direction: column;
    height: 100%;

    .payment-radio-item {
      display: flex;
      align-items: center;
      height: 100%;

      .checked-radio-shape {
        background: linear-gradient(90deg, #2d752f 0%, #9ec955 100%);
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        width: 24px;
        height: 24px;
        background-blend-mode: normal;
      }

      .unchecked-radio-shape {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
      }

      .payment-option-label {
        color: #303030;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
      }

      // .payment-option-divider {
      //   margin: 15px 30px;
      //   // width: 890px;
      //   height: 1px;
      //   background: #f0f0f0;
      //   background-blend-mode: normal;
      // }
    }
  }
}
.coupon-input {
    border: 1px solid rgb(125, 125, 125); 
    border-radius: 4px;
    height: 40px;
    padding: 10px;
    font-size: 16px;
    font-family: Poppins;
    width: 80%;
    margin-right: 2px;
}
