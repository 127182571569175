// Customizable Area Start
body {
  .custom-input {
    .MuiOutlinedInput-input {
      padding: 10px 12px;
      height: 48px;
      border-radius: 12px;
    }
  }
  .pagination-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 20px 20px;
    @media screen and (max-width: 600px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  .ReactTable {
    position: relative;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, 0.1);
    height: 75vh;
    border-radius: 12px;
    background: #ffffff;
    box-shadow: 0px 4px 12px rgb(0 0 0 / 7%);
    color: #212121;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 400;
    .rt-thead.-header {
      box-shadow: none !important;
      border-bottom: 1px solid #e6e6e6;
      min-width: 800px !important;
      height: 60px !important;
      display: flex;
      flex-direction: row;
      align-items: center;
      .rt-tr {
        text-align: start;
      }
    }

    .rt-tr-group {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: auto;
    }
    .rt-table {
      border-bottom: 1px solid #e6e6e6;
    }
    .rt-tbody {
      min-width: 800px !important;
    }
    .rt-td {
      overflow: visible !important;
      white-space: normal;
    }
    .ReactTable .rt-thead .rt-th.-cursor-pointer,
    .ReactTable .rt-thead .rt-td.-cursor-pointer {
      cursor: pointer;
      height: 50px;
    }
    .ReactTable .rt-thead .rt-resizable-header-content {
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 10px;
      height: 21px;
      color: rgba(33, 33, 33, 0.8);
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      text-align: initial;
      margin-left: 10px;
    }
    .ReactTable .rt-tbody {
      flex: 99999 1 auto;
      display: flex;
      flex-direction: column;
      overflow: auto;
      border: none !important;
      height: 24px;
      color: #212121;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin-left: 10px;
      border-bottom: none !important;
    }
    .ReactTable .rt-thead .rt-th,
    .ReactTable .rt-thead .rt-td {
      padding: 5px 5px;
      line-height: normal;
      position: relative;
      border-right: none;
      transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      box-shadow: inset 0 0 0 0 transparent;
    }
    .pagination-bottom {
      width: 100%;
      // display: none;
    }
    .MuiPaginationItem-page.Mui-selected {
      background: linear-gradient(90deg, #2d752f 0%, #9ec955 100%);
      background-blend-mode: normal;
      border-radius: 6px;
      border: none;
      color: white;
    }
    .MuiPaginationItem-root {
      border-radius: 6px;
      border: 1px solid #e4e4e4;
      background: #ffffff;
      background-blend-mode: normal;
    }
  }
}
// Customizable Area End
