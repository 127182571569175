.login_button {
  border-radius: 8px !important;
  // min-width: 4.5rem !important;
  // height: 50px !important;
  padding: 10px 20px !important;
  font-weight: Bold !important;
  background: linear-gradient(90deg, #2d752f 0%, #9ec955 100%) !important;
  color: #fff !important;
}

.nav-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 30px;
  @media screen and (max-width: 375px) {
    margin-left: 0px;
    justify-content: space-between;
    .MuiToolbar-gutters {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  @media screen and (max-width: 600px) {
    margin-left: 0px;
    justify-content: space-between;
    .MuiToolbar-gutters {
      margin-left: 20px;
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  .logo {
    @media screen and (max-width: 370px) {
      width: 110px;
      height: 50px;
    }
    @media screen and (max-width: 600px) {
      width: 130px;
      height: 55px;
    }
    object-fit: contain;
    cursor: pointer;
  }
}

.cat-container {
  display: flex;
  flex-wrap: nowrap;
  background-color: #212121;
  overflow: auto;
  white-space: nowrap;
  padding-left: 18px;
  @media screen and (max-width: 820px) {
    margin-top: 25px;
  }
  @media screen and (max-width: 600px) {
    margin-top: 35px;
  }
}

.cat-container::-webkit-scrollbar {
  display: none;
}

.first-cat {
  margin-left: 8px !important;
}

.cat-listing {
  color: #fff;
  font-family: Poppins !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  padding: 18px 12px !important;
  margin: 0 10px !important;
  cursor: pointer !important;
}
.search-tab {
  position: relative;
  background-color: #fff;
  @media screen and (max-width: 820px) {
    position: absolute;
    top: 50px;
    min-width: 150px !important;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
    min-width: 100% !important;
  }
}
.search-input {
  width: 500px;
    border-radius: 12px !important;
    border: 1px solid #ebebeb !important;
  @media screen and (max-width: 1100px) {
    width: 450px;
  }
  @media screen and (max-width: 1070px) {
    width: 500px;
  }
  @media screen and (max-width: 971px) {
    width: 400px;
  }
  @media screen and (max-width: 871px) {
    width: 360px;
  }
  @media screen and (max-width: 820px) {
    width: 500px;
    min-width: 150px !important;
  }
  @media screen and (max-width: 600px) {
    position: relative !important;

    // width: 100% !important;
    height: 40px !important;
    background: #f8fafb;
    // margin-left: 12px !important;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
    min-width: 100% !important;
  }
}
.search_modal {
  position: absolute;
  top: 45px;
  left: 0px;
  z-index: 15;
  width: 500px;
  min-height: 0px;
  border: 1px dashed #e4e4e4;
  color: #000;
  background-color: #fff;
  border-radius: 12px;
  padding: 10px;
  @media screen and (max-width: 1100px) {
    width: 450px;
  }
  @media screen and (max-width: 1070px) {
    width: 500px;
  }
  @media screen and (max-width: 971px) {
    width: 400px;
  }
  @media screen and (max-width: 871px) {
    width: 360px;
  }
  @media screen and (max-width: 820px) {
    min-width: 200px !important;
    width: 500px;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
    min-width: 100% !important;
  }
}

.search_suggestion_cont {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.search_suggestion_err {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.suggestion_text {
  padding: 1.5px 15px !important;
  font-family: Poppins !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}

.suggestion_feat_prod_text {
  font-size: 13px !important;
  font-family: Poppins !important;
  color: #212121;
  font-weight: 400 !important;
}

.suggestion_feat_cat_text {
  font-size: 12px !important;
  font-family: Poppins !important;
  color: #888;
  font-weight: 400 !important;
}

.profile-dropdown {
  position: absolute;
  width: 150px;
  top: 45px;
  left: 0;
  background-color: #eee;
  border-radius: 8px;
  box-shadow: -1px 1px 20px 0px rgba(146, 146, 146, 0.75);
  z-index: 10;
  @media screen and (min-width: 300px) and (max-width: 345px) {
    left: -65px;
    width: 100px;
  }
  @media screen and (min-width: 350px) and (max-width: 385px) {
    left: -45px;
    width: 100px;
  }
  @media screen and (min-width: 390px) and (max-width: 600px) {
    left: unset;
    right: 10px;
    width: 100px;
  }
  @media screen and (max-width: 1070px) {
    right: 0;
    left: unset;
    width: 120px;
  }
}

.dropdown-tab {
  padding: 1px;
  border-bottom: 1px solid #d1d1d1af;
}

.dropdown-tab:last-child {
  border-bottom: none;
}

.dropdown-text {
  color: #212121;
  font-size: 16px;
  font-family: Poppins;
  margin: 10px;
}

.logout-text {
  color: #ca2d1c;
}

.overlay2 {
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 9;
}

.cartCountCon{
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: #ff8502;
    top: -6px;
    right: 0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #fff;
}

.cartCount {
    color: #fff;
    font-family: Poppins;
    font-size: 8px;
    font-weight: 600;
}