.referral_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.referral-text-1{
    font-size: 24px !important;
    font-family: Poppins !important;
    color: #212121 !important;
    margin: 40px 0 30px 0 !important;
}

.referral-text-2 {
    font-size: 16px !important;
    font-family: Poppins !important;
    color: #777 !important;
    margin: 10px 0 30px 0 !important;
}

.referral-code-div {
    display: flex;
    align-items: center;
    margin: 20px 0 !important;
    padding: 10px 30px;
    background-color: #ffefdd;
}

.referral-code {
    font-size: 16px !important;
    font-family: Poppins !important;
    color: #ff8502 !important;
    font-weight: 600 !important;
    margin-right: 20px !important;
}

.referral-share {
    background: linear-gradient(90deg, #2d752f 0%, #9ec955 100%);
    height: 40px;
    color: #fff !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    font-family: Poppins !important;
    padding: 5px 20px !important;
    border-radius: 12px !important;
}