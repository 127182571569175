.h4-about {
  height: 39px;
  /* background: #212121; */
  // color: #212121;
  font-family: Poppins;
  font-size: 26px;
  font-weight: 600;
  line-height: 39px;
}
.p-about {
  // width: 972px;

  //  color: #212121;
  font-family: Poppins;
  //font-size: 16px;
  // font-weight: 600;
  line-height: 26px;
}
.div-about {
  padding: 40px;
  // min-height: 90vh;
  height: auto;
  // max-height: 100%;
  // margin-bottom: auto;
  strong {
    height: 39px;
    /* background: #212121; */
    color: #212121;
    font-family: Poppins;
    font-size: 16px !important;
    font-weight: 600;
    line-height: 39px;
    // background-color: #f8fafb !important;
  }
  p {
    font-family: Poppins;
    //font-size: 16px;
    // font-weight: 600;
    line-height: 20px !important;
  }
  span {
    // background-color: #f8fafb !important;
  }
}
.img-about {
  width: 50%;
  /* height: 70px; */
  border-radius: 8px;
  background: #d8d8d8;
}
// .div-both-img{
//     margin-right: 30px;
//     margin-left: 30px;
// }
.div-about-img {
  display: flex;
}
