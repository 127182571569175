.container {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	font-family: 'Poppins', sans-serif;
	.banner {
		width: 50%;
		position: relative;
		@media screen and (max-width: 540px) {
			width: 100%;
		}
		.logo {
			@media screen and (max-width: 768px) {
				left: 30px;
			}

			top: 50px;
			left: 50px;
			position: absolute;
			object-fit: fill;
			z-index: 99;
			width: clamp(100px, 70%, 240px);
		}
		.banner-img1 {
			max-height: 100vh;
			width: 100%;
			object-fit: cover;
			position: relative;
			@media screen and (max-width: 540px) {
				height: 150px;
			}
		}
		.rights-reserved {
			opacity: 0.85;
			position: absolute;
			left: 50px;
			bottom: 30px;
			color: #ffffff;
			font-family: Poppins;
			font-size: 14px;
			font-weight: 500;
			text-align: left;
			@media screen and (max-width: 991px) {
				left: 30px;
			}
			@media screen and (max-width: 540px) {
				bottom: 10px;
			}
		}
	}
	.auth-form-wrapper {
		width: 50%;
		@media screen and (max-width: 991px) {
			width: 70%;
		}
		@media screen and (max-width: 540px) {
			width: 100%;
		}
		.auth-form {
			position: relative;
			margin: 10px;
		}
		.signup-link {
			position: absolute;
			top: 20px;
			right: 20px;
			width: 100%;
			text-align: right;
			@media screen and (max-width: 540px) {
				text-align: center;
				right: auto;
			}
			a {
				color: orange;
				text-decoration: none;
				font-size: 16px;
				font-weight: 600;
			}
		}
		.reset-form {
			@media screen and (max-width: 768px) {
				width: 80%;
			}

			width: 70%;
			margin: 0 auto;
			padding-top: 100px;
			.MuiFormControl-root {
				padding: 5px 0;
				width: 100%;
				.MuiOutlinedInput-root {
					width: 100% !important;
				}
			}
			input {
				border-radius: 15px;
				height: 48px;
				width: 100%;
			}
			.input-lable {
				font-weight: 500px;
			}
            .custom-input {
                border-radius: 15px;
            }
			.reset-heading {
				font-style: normal;
				font-weight: 600;
				font-size: 36px;
				line-height: 54px;
				/* identical to box height */
				color: #212121;
				@media screen and (max-width: 768px) {
					font-size: 32px;
				}
			}
            .back-arrow {
                margin-top: 22px;
                margin-left: -6px;
                cursor: pointer;

            }
		}
		.remember-me-section {
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;
			.remember-checkbox {
				color: orange;
			}
			.forgot-password {
				color: orange;
				text-decoration: none;
				font-size: 16px;
				font-weight: 600;
			}
		}
		.submit-button {
			width: 100%;
			background-color: #2d752f;
			color: white;
			background: linear-gradient(90deg, #2d752f 0%, #9ec955 100%);
			background-blend-mode: normal;
			height: 56px;
			display: flex;
			justify-content: space-evenly;
			border-radius: 12px;
			.loader {
				width: 10px;
				height: 10px;
				color: white;
				font-size: 10px;
			}
			span {
				color: #ffffff;
				font-family: Poppins;
				font-size: 16px;
				font-weight: 500;
				line-height: 16px;
				text-align: center;
				text-transform: uppercase;
			}
		}
		.divider-section {
			display: flex;
			justify-content: space-between;
			align-content: center;
			align-items: center;
			p {
				color: grey;
				font-size: 15px;
			}
			.divider {
				width: 43%;
				color: black;
			}
		}
	}
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-text-fill-color: #000 !important;
	transition: background-color 5000s ease-in-out 0s !important;
}
