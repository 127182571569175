.logout-box-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgb(255, 255, 255);
  border-radius: 10px;
  font-family: Poppins;
  font-style: normal;
  width: 540px;
  height: auto !important;
  border-radius: 12px;
  background: #ffffff;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.24);
  padding: 50px 50px 20px 50px !important;
  @media screen and (min-width: 400px) and (max-width: 600px) {
    width: 360px;
  }
  @media screen and (max-width: 400px) {
    width: 310px;
    padding: 20px 20px 20px 20px !important;
  }
}
.div-modal {
  text-align: center !important;
}
.div-modal-h4 {
  text-align: center !important;
  height: 36px;
  color: #212121;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-align: center;
  @media screen {
    
  }
}
.p-tag-modal {
  height: 50px;
  color: #424242;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
  margin-bottom: 30px;
}
.modal-bttn {
  // width: 412px !important;
  width: 45%;
  height: 56px !important;
  background: linear-gradient(90deg, #2d752f, #9ec955);
  color: #ffffff !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 16px !important;
  text-align: center !important;
  text-transform: uppercase !important;
  border-radius: 12px !important;
  cursor: pointer !important;
}
.logout-divs {
  display: flex;
  margin-bottom: 25px;
  width: 100%;
  justify-content: space-between;
}
.modal-cancle-btn {
  color: #000000;
  width: 45%;
  height: 56px;
  background: #fff !important;
  background-blend-mode: normal;
  height: 56px;
  border: none !important;
  border: 1px solid rgb(189, 189, 189) !important;
  border-radius: 12px !important;

  span {
    color: #000000;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
  }
}
