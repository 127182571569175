.container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  font-family: "Poppins", sans-serif;
  .banner {
    width: 50%;
    position: relative;
    @media screen and (max-width: 991px) {
      width: 30%;
    }
    @media screen and (max-width: 540px) {
      width: 100%;
    }
    .logo {
      @media screen and (max-width: 768px) {
        left: 30px;
      }

      top: 50px;
      left: 50px;
      position: absolute;
      object-fit: fill;
      z-index: 99;
      width: clamp(100px, 70%, 240px);
    }
    .banner-img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      position: relative;
      @media screen and (max-width: 540px) {
        height: 150px;
      }
    }
    .rights-reserved {
      opacity: 0.85;
      position: absolute;
      left: 50px;
      bottom: 30px;
      color: #ffffff;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      text-align: left;
      @media screen and (max-width: 991px) {
        left: 30px;
      }
      @media screen and (max-width: 540px) {
        bottom: 10px;
      }
    }
  }
  .auth-form-wrapper {
    width: 50%;
    @media screen and (max-width: 991px) {
      width: 70%;
    }
    @media screen and (max-width: 540px) {
      width: 100%;
    }
    .auth-form {
      position: relative;
      margin: 10px;
    }
    .signup-link {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 100%;
      text-align: right;
      @media screen and (max-width: 540px) {
        text-align: center;
        right: auto;
      }
      a {
        color: orange;
        text-decoration: none;
        font-size: 16px;
        font-weight: 600;
      }
    }
    .login-form {
      @media screen and (max-width: 768px) {
        width: 80%;
      }
      width: 70%;
      margin: 0 auto;
      padding-top: 100px;
      .welcome {
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 54px;
        /* identical to box height */
        color: #212121;
        @media screen and (max-width: 768px) {
          font-size: 32px;
        }
      }
      .selected {
        border: solid #478938;
      }
      .unSelected {
        border: solid #e9e9e9;
      }
      .option-container {
        cursor: pointer;
        height: 194px;
        border-radius: 12px;
        background: #f9f9f9;
        background-blend-mode: normal;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-content: center;
      
        .logo-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 30%;
          img {
            width: 115.55px;
            height: 133.52px;
          }
        }
        .info-container {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-content: center;
          width: 60%;
          h2 {
            height: 36px;
            color: #212121;
            font-size: 24px;
            font-weight: 600;
          }
          p {
            color: #212121;
            font-size: 16px;
            font-weight: 300;
            line-height: 26px;
          }
          img {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
    .login-button {
      width: 100%;
      background-color: #2d752f;
      color: white;
      background: linear-gradient(90deg, #2d752f 0%, #9ec955 100%);
      background-blend-mode: normal;
      height: 56px;
      display: flex;
      justify-content: space-evenly;
      border-radius: 12px;
      cursor: pointer;
      .loader {
        width: 10px;
        height: 10px;
        color: white;
        font-size: 10px;
      }
      span {
        color: #ffffff;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
        text-align: center;
        text-transform: uppercase;
      }
    }
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: #000 !important;
  transition: background-color 5000s ease-in-out 0s !important;
}
.div-rember{
  display: flex !important;
  .MuiIconButton-label {
    width: 100% !important;
    display: flex !important;
    align-items: inherit !important;
    margin-top: -3px !important;
    margin-bottom: 0px !important;
    justify-content: inherit !important;
}
}
.terms-condition{
  color: #f44336;
    margin: 0;
    font-size: 0.75rem;
    margin-top: 3px;
    text-align: left;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
}
