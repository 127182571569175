body {
  .w-100 {
    width: 100%;
  }
  .w-90 {
    width: 90%;
  }
  h2 {
    margin: 7px 0 7px 0;
  }
  h3 {
    margin: 7px 0 7px 0;
  }
  h4 {
    margin: 7px 0 7px 0;
  }
  p {
    margin: 5px 0 5px 0;
  }
}
