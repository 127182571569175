.MuiTabs-indicator {
  background-color: #ff8502 !important;
  width: 21px !important;
  height: 3px !important;
  border-radius: 1.5px !important;
  margin-left: 13px !important;
}
.MuiTab-root {
  min-width: 0px !important;
}
.MuiTabs-flexContainer {
  display: flex !important;
  margin-left: 20px !important;
}
.MuiTab-textColorInherit.Mui-selected {
  opacity: 1;
  /* width: 20px; */
  height: 24px !important;
  color: #ff8502 !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
}
.MuiTab-wrapper {
  width: 100% !important;
  display: inline-flex !important;
  align-items: center !important;
  flex-direction: column !important;
  justify-content: center !important;
  text-transform: capitalize !important;
  //color: #838d80 !important;
}
.MuiTab-textColorInherit {
  color: #838d80 !important;
}

.MuiDialog-scrollPaper {
  justify-content: flex-end !important;
  min-height: 100vh;
}
.MuiDialog-paper {
  margin: 0 !important;
  min-height: 100vh;
  // overflow-y: scroll;
}

.rt-tr-group {
  cursor: pointer !important;
  border: none !important;
}
.img-slide {
  margin: 0 auto;
  width: 400px;
  height: 400px;
  @media screen and (max-width: 600px) {
    width: 200px;
    height: 200px;
  }
}

.img {
  width: 400px;
  min-height: 400px;
  max-height: 400px !important;
  background-position: center;
  background-size: cover;
  border-radius: 8px;
  border: 1px dashed #f0f0f0;
}
.prodImage {
  width: 400px;
  min-height: 400px;
  max-height: 400px !important;
  background-position: center;
  background-size: cover;
  border-radius: 8px;
  border: 1px dashed #f0f0f0;
  @media screen and (max-width: 600px) {
    width: 200px;
    min-height: 200px;
    max-height: 200px !important;
    object-fit: contain;
  }
}
.table-box {
  //   width: calc(100% - 250px);
  margin-left: 250px;
  @media screen and (max-width: 600px) {
    margin-left: 0px;
  }
}
.outOfStockDiv {
  width: 440px;
  margin: 20px auto;
  border-radius: 8px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 600px) {
    width: 200px;
  }
}

.outOfStockDivLoader {
  display: flex;
  align-items: center;
  // justify-content: space-around;
  // width: 100px;
}

.MuiFormControlLabel-root {
  margin-right: 0 !important;
}

.MuiDialog-paperWidthSm {
  min-width: 35% !important;
}

.DetailsButtonCon {
  display: flex;
  justify-content: space-around !important;
  padding: 20px 0;
  @media screen and (max-width: 600px) {
    padding: 0px;
    flex-direction: column;
  }
  .ButtonDelete {
    width: 13rem;
    height: 50px !important;
    background-color: #ffe5e5 !important;
    color: #f53030 !important ;
    font-family: "Poppins" !important;
    border-radius: 8px !important;
    padding: 10px 25px !important;
    @media screen and (max-width: 600px) {
      margin-left: 0px !important;
      width: 12.5rem !important;
      margin-top: 10px;
    }
  }

  .ButtonEdit {
    box-sizing: border-box !important;
    color: #000 !important;
    width: 12.5rem;
    font-family: "Poppins" !important;
    border-radius: 8px !important;
    height: 50px !important;
    padding: 10px 25px !important;
    border: 0.3px solid #939393 !important;
    @media screen and (max-width: 600px) {
      // width: 100% !important;
    }
  }
}
.cancelButtonCon {
  display: flex;
  justify-content: space-around !important;
  // padding: 20px 0;
  margin: 20px 0px;
  @media screen and (max-width: 600px) {
    padding: 0px;
    flex-direction: column;
  }
  .ButtonEditw {
    box-sizing: border-box !important;
    color: #000 !important;
    width: 13rem;
    font-family: "Poppins" !important;
    border-radius: 8px !important;
    height: 50px !important;
    padding: 10px 25px !important;
    border: 0.3px solid #939393 !important;
    @media screen and (max-width: 600px) {
      width: 90% !important;
      margin-left: 15px !important;
      margin-bottom: 15px !important;
    }
  }
  .saveButton {
    border-radius: 8px !important;
    width: 13rem !important;
    height: 100% !important;
    background: linear-gradient(90deg, #2d752f 0%, #9ec955 100%) !important;
    color: #fff !important;
    @media screen and (max-width: 600px) {
      width: 90% !important;
      padding: 10px 25px !important;
      margin-left: 15px !important;
      margin-bottom: 15px !important;
    }
  }
}
.previewImg {
  position: relative;
  width: 120px !important;
  height: 120px;
  margin: 0 10px 10px 0;
}

.cross-circle-cancel-img {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 10px;
  height: 10px;
  color: #e42f2f;
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
}

.MuiTabs-flexContainer {
  overflow: auto;
}

.MuiTabs-indicator {
  display: none;
}

.rt-tr {
  align-items: center;
}

.rt-tr-group {
  cursor: auto !important;
}

#form-dialog-title {
  font-weight: 800;
  font-family: "Poppins";
}

#prodInput,
.Select__control {
  min-height: 48px !important;
  font-size: 16px;
  font-family: "Poppins";
  border-radius: 12px !important;
}

.Select__placeholder {
  color: hsl(0, 0, 60%) !important;
}

.prodInput {
  width: 100%;
}

.subProdInput {
  width: 90%;
}

.MuiOutlinedInput-root {
  border-radius: 12px !important;
}

.img-upload {
  width: 120px;
  height: 120px;
  display: grid;
  place-items: center;
  border: 1.5px dashed #ff8502;
  margin-right: 10px;
  border-radius: 4px;
  cursor: pointer;
}

.moreInfoCon {
  box-shadow: 0px 2px 10px -2px rgba(170, 170, 170, 0.7);
  background-color: #f9f9f9;
  color: #000;
  position: absolute;
  right: 20px;
  z-index: 999;
  padding: 5px 1px 2px 1px;
  border-radius: 12px;
}

.others {
  top: 20px;
}

.four {
  top: -60px;
}

.five {
  bottom: -10px;
}

.modalDialog {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  max-height: 200px !important;
}
.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: -1;
}
.change-delet-box-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgb(255, 255, 255);
  border-radius: 10px;
  font-family: Poppins;
  font-style: normal;
  width: 540px;
  height: auto !important;
  border-radius: 12px;
  background: #ffffff;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.24);
  padding: 50px 50px 20px 50px !important;
  @media screen and (min-width: 400px) and (max-width: 600px) {
    width: 360px;
  }
  @media screen and (min-width: 300px) and (max-width: 400px) {
    width: 310px;
    padding: 20px 20px 20px 20px !important;
  }
}
.change-delet-box-div {
  display: flex;
  margin-bottom: 25px;
  width: 100%;
  justify-content: space-between;
}

.modal-remove-btn {
    width: 45%;
    height: 56px !important;
    background: linear-gradient(90deg, #2D752F, #9EC955);
    color: #ffffff !important;
    font-family: Poppins !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 16px !important;
    text-align: center !important;
    text-transform: uppercase !important;
    border-radius: 12px !important;
    cursor: pointer !important;
}
.modal-cancel-btn {
  color: #000000;
  width: 45%;
  height: 56px;
  background: #fff !important;
  background-blend-mode: normal;
  height: 56px;
  border: none !important;
  border: 1px solid rgb(189, 189, 189) !important;
  border-radius: 12px !important;

  span {
    color: #000000;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
  }
}
.modal-delet-btn {
  // width: 412px !important;
  width: 45%;
  height: 56px !important;
  background: linear-gradient(90deg, #2d752f, #9ec955);
  color: #ffffff !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 16px !important;
  text-align: center !important;
  text-transform: uppercase !important;
  border-radius: 12px !important;
  cursor: pointer !important;
}
//show prodDetails
.detailsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.img-product-close {
  width: 16px;
  height: 16px;
}
.detailsCloseIcon {
  // @media screen and (max-width: 600px) {
  //   margin-right: 0px;
  //   margin-left: 0px;
  //   margin-top: 0px;
  //   // margin-right: 35px;
  // }
  //
  // cursor: pointer;
  margin-left: auto;
  margin-top: 10px;
  margin-right: 35px;
  cursor: pointer;
}
